// zIndices.js
//zIndex:  [background]11, [base]10, [mouth]9, [blemish]8, [neck]7, [facialHair]6, [ear]5, [hair]4, [mouthprop]3, [eyes]2, [nose]1

export const zBackground = 13;
export const zBKGoverlay = 12;
export const zBase = 11;
export const zMouth = 10;
export const zBlemish = 9;
export const zNeck = 8;
export const zFacialHair = 7;
export const zEar = 6;
export const zHair = 5;
export const zMouthProp = 4;
export const zEyes = 3;
export const zNose = 2;
export const zAnimation = 1;
// Add more z-index values as needed