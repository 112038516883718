import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import BuildCanvas from './components/buildCanvas';
import { shadedAssets } from './Shaded_ImageAssets';
import { OGAssets } from './OG_ImageAssets';
import { SpecialImageAssets } from './SpecialImageAssets';
import * as zIndices from './zIndices';
import './PunkBuilder.css';
import TwitterLink from './TwitterLink';
import TopHatMenu from './TopHatMenu';
import placeholderImage from './Assets/PunkCanvasPlaceholder.gif';
import popupGif from './Assets/SupportPopup.gif';
import TraitImage from './TraitImage';
import punkData from "./Assets/punkattributes.json";

const App = () => {
  const [showBaseTraits, setShowBaseTraits] = useState(null);
  const [showFemaleBase, setShowFemaleBase] = useState(null);
  const [showMaleBase, setShowMaleBase] = useState(null);
  const [showFemaleTraits, setShowFemaleTraits] = useState(null);
  const [showMaleTraits, setShowMaleTraits] = useState(null);
  const [OGArray, setOGArray] = useState([]);
  const [shadedArray, setShadedArray] = useState([]);
  const [shadedChecked, setShadedChecked] = useState(true);
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [matchedPunkID, setMatchedPunkID] = useState(null);
  const url = `https://cryptopunks.app/cryptopunks/details/${matchedPunkID}`;
  const [matchedPunkBlurb, setMatchedPunkBlurb] = useState(null);

  const [rulesChecked, setRulesChecked] = useState(true);
  const [OGChecked, setOGChecked] = useState(true);
  const [pilotHelmetSelected, setPilotHelmetSelected] = useState(null);
  const [WeldingGogglesSelected, setWeldingGogglesSelected] = useState(null);
  const [apeAlienSelected, setApeAlienSelected] = useState(null);

  const handleButtonClick = () => {
    setShadedChecked(prevState => !prevState);
  };

  const handleButtonRulesClick = () => {
    setRulesChecked(prevState => !prevState);
    reset();
  };

  const handleButtonOGClick = () => {
    setOGChecked(prevState => !prevState);
    reset();
  };

  const updateImageArray = (traitname) => {
    const zIndex = OGAssets[traitname].zIndex;
    const traitName = OGAssets[traitname].alt;
    console.log('Trait:', traitName);
    //console.log('zIndex:', zIndex);
  
    const updatedOGArray = [...OGArray];
    const updatedShadedArray = [...shadedArray];
  
    if (
      traitname === "bkgNone" ||
      traitname === "eyesNone" ||
      traitname === "hairNone" ||
      traitname === "facialhairNone"
      // Add more conditions here for other trait names
     ) {
      updatedOGArray[zIndex] = null;
      updatedShadedArray[zIndex] = null;
      selectedTraits[zIndex] = "None";
    } else {
      updatedOGArray[zIndex] = { image: OGAssets[traitname].image };
      updatedShadedArray[zIndex] = { image: shadedAssets[traitname].image };
      selectedTraits[zIndex] = OGAssets[traitname].alt;
    }

    if (traitname === "othersNone") { //resets other traits
    
      updatedOGArray[zIndices.zBlemish] = null;
      updatedOGArray[zIndices.zEar] = null;
      updatedOGArray[zIndices.zMouth] = null;
      updatedOGArray[zIndices.zMouthProp] = null;
      updatedOGArray[zIndices.zNeck] = null;
      updatedOGArray[zIndices.zNose] = null;
  
      updatedShadedArray[zIndices.zBlemish] = null;
      updatedShadedArray[zIndices.zEar] = null;
      updatedShadedArray[zIndices.zMouth] = null;
      updatedShadedArray[zIndices.zMouthProp] = null;
      updatedShadedArray[zIndices.zNeck] = null;
      updatedShadedArray[zIndices.zNose] = null;
  
      selectedTraits[zIndices.zBlemish] = null;
      selectedTraits[zIndices.zEar] = null;
      selectedTraits[zIndices.zMouth] = null;
      selectedTraits[zIndices.zMouthProp] = null;
      selectedTraits[zIndices.zNeck] = null;
      selectedTraits[zIndices.zNose] = null;
    
      setOGArray(updatedOGArray);
      setShadedArray(updatedShadedArray);
    }

    if (rulesChecked) { //only enforce if Rules is selected
      if (
        traitname === "Ape" || traitname === "Alien"//Apes and Aliens have no human hair trait
        ) {
          setApeAlienSelected(true);
          selectedTraits[zIndices.zFacialHair] = null;
          updatedOGArray[zIndices.zFacialHair] = null;
          updatedShadedArray[zIndices.zFacialHair] = null;
          if (
            selectedTraits[zIndices.zHair] === "Clown Hair Green" ||
            selectedTraits[zIndices.zHair] === "Crazy Hair" ||
            selectedTraits[zIndices.zHair] === "Frumpy Hair" ||
            selectedTraits[zIndices.zHair] === "Messy Hair" ||
            selectedTraits[zIndices.zHair] === "Mohawk Dark" ||
            selectedTraits[zIndices.zHair] === "Mohawk Thin" ||
            selectedTraits[zIndices.zHair] === "Mohawk" ||
            selectedTraits[zIndices.zHair] === "Peak Spike" ||
            selectedTraits[zIndices.zHair] === "Purple Hair" ||
            selectedTraits[zIndices.zHair] === "Shaved Head" ||
            selectedTraits[zIndices.zHair] === "Stringy Hair" ||
            selectedTraits[zIndices.zHair] === "Vampire Hair" ||
            selectedTraits[zIndices.zHair] === "Wild Hair" ||
            selectedTraits[zIndices.zHair] === "Genuine Spiky Pink" ||
            selectedTraits[zIndices.zHair] === "Genuine Spiky Red" ||
            selectedTraits[zIndices.zHair] === "WSB" ||
            selectedTraits[zIndices.zHair] === "Afro" ||
            selectedTraits[zIndices.zHair] === "Brush it like Becks" ||
            selectedTraits[zIndices.zHair] === "Centre Parting" ||
            selectedTraits[zIndices.zHair] === "Side Flop" ||
            selectedTraits[zIndices.zHair] === "Chairman" ||
            selectedTraits[zIndices.zHair] === "Edwardian" ||
            selectedTraits[zIndices.zHair] === "Georgian" ||
            selectedTraits[zIndices.zHair] === "Elvis" ||
            selectedTraits[zIndices.zHair] === "Long Blonde" ||
            selectedTraits[zIndices.zHair] === "Short Black" ||
            selectedTraits[zIndices.zHair] === "Short Blonde" ||
            selectedTraits[zIndices.zHair] === "Short Brown"
            ) {
            selectedTraits[zIndices.zHair] = null;
            updatedOGArray[zIndices.zHair] = null;
            updatedShadedArray[zIndices.zHair] = null;
            }
          if (
            selectedTraits[zIndices.zEyes] === "Clown Eyes Blue" ||
            selectedTraits[zIndices.zEyes] === "Clown Eyes Green"
          ){
            selectedTraits[zIndices.zEyes] = null;
            updatedOGArray[zIndices.zEyes] = null;
            updatedShadedArray[zIndices.zEyes] = null;
          }
          if (
            selectedTraits[zIndices.zMouth] === "Buck Teeth" ||
            selectedTraits[zIndices.zMouth] === "Frown" ||
            selectedTraits[zIndices.zMouth] === "Smile"
          ){
            selectedTraits[zIndices.zMouth] = null;
            updatedOGArray[zIndices.zMouth] = null;
            updatedShadedArray[zIndices.zMouth] = null;
          }
          if (
            selectedTraits[zIndices.zBlemish] === "Mole" ||
            selectedTraits[zIndices.zBlemish] === "Rosy Cheeks" ||
            selectedTraits[zIndices.zBlemish] === "Spots"
          ){
            selectedTraits[zIndices.zBlemish] = null;
            updatedOGArray[zIndices.zBlemish] = null;
            updatedShadedArray[zIndices.zBlemish] = null;
          }
      } else if (zIndex === zIndices.zBase) {setApeAlienSelected(false)}

      if (
        traitname === "fPilotHelmet" //Pilot Helmet trait has no eye trait
      ) {
          setPilotHelmetSelected(true);
          updatedOGArray[zIndices.zEyes] = null;
          updatedShadedArray[zIndices.zEyes] = null;
          selectedTraits[zIndices.zEyes] = null;
      } else if (zIndex === zIndices.zHair) {setPilotHelmetSelected(false)};

      if (
        traitname === "fWeldingGoggles" //Welding Goggles have no hat trait
      ) {
          setWeldingGogglesSelected(true);
          if (
            selectedTraits[zIndices.zHair] === "Bandana" ||
            selectedTraits[zIndices.zHair] === "Cap" ||
            selectedTraits[zIndices.zHair] === "Headband" ||
            selectedTraits[zIndices.zHair] === "Knitted Cap" ||
            selectedTraits[zIndices.zHair] === "Pilot Helmet" ||
            selectedTraits[zIndices.zHair] === "Pink With Hat" ||
            selectedTraits[zIndices.zHair] === "Tassle Hat" ||
            selectedTraits[zIndices.zHair] === "Tiara" ||
            selectedTraits[zIndices.zHair] === "Beanie" ||
            selectedTraits[zIndices.zHair] === "Cap Forward" ||
            selectedTraits[zIndices.zHair] === "Cowboy Hat" ||
            selectedTraits[zIndices.zHair] === "Fedora" ||
            selectedTraits[zIndices.zHair] === "Hoodie" ||
            selectedTraits[zIndices.zHair] === "Police Cap" ||
            selectedTraits[zIndices.zHair] === "Top Hat" ||
            selectedTraits[zIndices.zHair] === "Down Bad M Cap" ||
            selectedTraits[zIndices.zHair] === "Down Bad InOut Hat" ||
            selectedTraits[zIndices.zHair] === "Black CP Cap" ||
            selectedTraits[zIndices.zHair] === "Red Cap Reversed"
            ) {
            selectedTraits[zIndices.zHair] = null;
            updatedOGArray[zIndices.zHair] = null;
            updatedShadedArray[zIndices.zHair] = null;
          }
      } else if (zIndex === zIndices.zEyes) {setWeldingGogglesSelected(false)}
    }

    //console.log('Selected Trait Name:', selectedTraits[zIndex]);

    setOGArray(updatedOGArray);
    setShadedArray(updatedShadedArray);

    if (!showBaseTraits) {
      setShowBaseTraits(true);
      setShowFemaleBase(true);
      setShowMaleBase(true);
    }    

    if (traitname.toLowerCase().includes('female') ||
        traitname === "fxZombie" ||
        traitname === "fxApe" ||
        traitname === "fxAlien"
    ) {
      setShowFemaleTraits(true);
      setShowMaleTraits(null);
      setShowMaleBase(null);
    } else if (
        traitname.toLowerCase().includes('male') ||
        traitname === "Zombie" ||
        traitname === "Ape" ||
        traitname === "Alien"
    ) {
      setShowMaleTraits(true);
      setShowFemaleTraits(null);
      setShowFemaleBase(null);
    }

    const indicesToInclude = [zIndices.zBase, zIndices.zMouth, zIndices.zBlemish, zIndices.zNeck, zIndices.zFacialHair, zIndices.zEar, zIndices.zHair, zIndices.zMouthProp, zIndices.zEyes, zIndices.zNose];
    const filteredTraits = indicesToInclude
      .map(index => selectedTraits[index])
      .filter(trait => trait !== null && trait !== undefined && trait !== 'None')
      .join(", ");
    
    //console.log('Current traitString:', filteredTraits);
    const foundPunk = punkData.find((item) => item.attributes === filteredTraits);


    if (foundPunk) {
      setMatchedPunkID(foundPunk.punkID);
      setMatchedPunkBlurb(foundPunk.blurb);
      //console.log('Punk found with ID:', foundPunk.punkID);
      //console.log('Punk blurb:', foundPunk.blurb);
    } else {
      setMatchedPunkID(null);
      //console.log('No existing punk');
    }

    //console.log('selectedBase zBase',selectedTraits[zIndices.zBase]);

  };
  
  const reset = () => {
    setOGArray([]);
    setShadedArray([]);
    setSelectedTraits([]);
    setShowFemaleTraits(null);
    setShowMaleTraits(null);
    setShowFemaleBase(null);
    setShowMaleBase(null);
    setShowBaseTraits(null);
    setMatchedPunkID(null);
    setPilotHelmetSelected(null);
    setWeldingGogglesSelected(null);
    setApeAlienSelected(null);
  }

  const handleDownload = () => {
    const createAndDrawCanvas = (canvasWidth, canvasHeight) => {
      return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
  
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        context.imageSmoothingEnabled = false;
  
        // Reverse the order of the layers
        let reversedArray = shadedChecked ? [...shadedArray].reverse() : [...OGArray].reverse();
  
        // Load and draw the images from the reversed OGArray onto the canvas
        const imagePromises = reversedArray.map((layer) => {
          return new Promise((resolve, reject) => {
            if (layer && layer.image) {
              const image = new Image();
              image.onload = () => {
                context.drawImage(image, 0, 0, canvas.width, canvas.height);
                resolve();
              };
              image.onerror = reject;
              image.src = layer.image;
            } else {
              resolve(); // Resolve immediately if no image is present
            }
          });
        });
  
        Promise.all(imagePromises)
          .then(() => {
            resolve(canvas);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
  
    const targetSize = document.getElementById('outputSize').value;
  
    let canvasWidth, canvasHeight;
  
    if (targetSize === '480') {
      canvasWidth = 480;
      canvasHeight = 480;
    } else if (targetSize === '120') {
      canvasWidth = 120;
      canvasHeight = 120;
    } else if (targetSize === '1024') {
      canvasWidth = 1024;
      canvasHeight = 1024;
    } else if (targetSize === '24') {
      canvasWidth = 24;
      canvasHeight = 24;
    }
  
    createAndDrawCanvas(canvasWidth, canvasHeight)
      .then((canvas) => {
        // Create a temporary link element for download
        const link = document.createElement('a');
        link.href = canvas.toDataURL();
        link.download = matchedPunkID ? `punk${matchedPunkID}.png` : 'punk.png';
        link.click();
      })
      .catch((error) => {
        console.error('Error creating and drawing canvas:', error);
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 69000);

    return () => clearTimeout(timer);
  }, []);

  async function connectWallet() {
    // Check if MetaMask is installed
    if (window.ethereum) {
      try {
        // Request account access from the user
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        
        // Get the selected accounts
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        
        // Store the connected account
        const connectedAccount = accounts[0];
        
        // Perform any further actions with the connected account
        console.log('Connected account:', connectedAccount);
        
        // Add your custom logic here...
        
      } catch (error) {
        console.error('Error connecting to wallet:', error);
      }
    } else {
      console.error('MetaMask is not installed');
    }
  }
  

  return (
    <div>
        <Helmet>
          <html lang="en" />
          <title>The Punk Builder</title>
          <meta name="description" content="Build a punk from over 30 classic traits with this simple app, by The Punk Animator" />
        </Helmet>

        {showPopup && (
          <div className="popup-overlay">
            <img src={popupGif} alt="Popup" />
          </div>
        )}


<nav class="menu-bar">
  <TopHatMenu />
  <TwitterLink />
  <button className="connect-button" /*</nav>onClick={connectWallet}*/>my profile</button>
</nav>


    <div className="punkbuilder-container">
        <div className="container-header">
          <h1>THE PUNK BUILDER</h1>
          <h4 style={{ color: 'red' }}>&nbsp;&nbsp;&nbsp;by The Punk Animator</h4>
          <h5 style={{ color: 'blue' }}>&nbsp;&nbsp;with ChatGPT!</h5>
        </div>
    <div className="container-main">
        <div className="punk-canvas">
          <div className="punk-canvas-container">
            <BuildCanvas
              layers={shadedChecked ? shadedArray : OGArray}
              width={800} //arbitary number to be larger that css specification
              height={800}
              shadedArray={shadedArray}
              OGArray={OGArray}
            />
            {!showBaseTraits && (
              <img src={placeholderImage} alt="overlay-gif" className="overlay-gif" />
            )}
            </div>
          </div>
          <div className="traits-info">
            <h3>Selected Traits:</h3>
              <ul>
                {selectedTraits.slice().reverse().map((trait, index) => {
                  if (!trait) {
                    return null; // Skip rendering empty indexes
                  }
                  return <li key={index}>{trait}</li>;
                })}
              </ul>
              {selectedTraits[zIndices.zBase] && (
                <>
                  {matchedPunkID ? (
                    <h2 className="flashing-text" style={{ color: 'fuchsia', fontFamily: 'Montserrat, sans-serif' }}>
                      This is <a href={url} target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'fuchsia', borderBottom: '1px solid blue' }}>Punk {matchedPunkID}</a>
                      {matchedPunkID < 1000 && <span className="matchpunk-comment">, a rare dev punk!</span>}
                      <span className="matchpunk-comment">{matchedPunkBlurb}</span>
                    </h2>
                  ) : (
                    <h3 style={{ color: 'blue', fontFamily: 'Montserrat, sans-serif' }}>
                      
                    </h3>
                  )}
                </>
              )}
            </div>
        </div>
          <div className="container-footer">
            <select id="outputSize" defaultValue="480">
              <option value="1024">1024 px</option>
              <option value="480">480 px</option>
              <option value="120">120 px</option>
              <option value="24">24 px</option>
            </select>
            <button className="download-button" onClick={() => handleDownload()}><h2>Download</h2></button>
            <button className="button-shading" onClick={handleButtonClick}>{shadedChecked ? 'Shading On' : 'Shading Off'}</button>
            <button className="button-rules" onClick={handleButtonRulesClick}>{rulesChecked ? 'Rules On' : 'Rules Off'}</button>
            <button className={`button-OG ${OGChecked ? 'active' : ''}`} onClick={handleButtonOGClick}>{OGChecked ? 'OG traits' : 'All Traits'}</button>
            <button className="reset-button" onClick={reset}><h2>RESET</h2></button>
          </div>
    </div>
    <div className="gap"></div>
      <div className="trait-selection-section">
          <div className="section-header"><h2>Background</h2></div>
              <TraitImage src={OGAssets.OG.image} onClick={() => updateImageArray('OG')} alt="OG"/>
              <TraitImage src={OGAssets.Gradient.image} onClick={() => updateImageArray('Gradient')} alt="Gradient" />
              <TraitImage src={OGAssets.V1.image} onClick={() => updateImageArray('V1')} alt="V1"/>
              <TraitImage src={OGAssets.OGV1.image} onClick={() => updateImageArray('OGV1')} alt="OG & V1"/>
              <TraitImage src={OGAssets.xPunk.image} onClick={() => updateImageArray('xPunk')} alt="xPunks" />
              <TraitImage src={OGAssets.BTC.image} onClick={() => updateImageArray('BTC')} alt="Bitcoin" />
              <TraitImage src={OGAssets.SOL.image} onClick={() => updateImageArray('SOL')} alt="Solana" />
              <TraitImage src={OGAssets.White.image} onClick={() => updateImageArray('White')} alt="White" />
              <TraitImage src={OGAssets.NoneCheckboard.image} onClick={() => updateImageArray('bkgNone')} alt="None" />
              <TraitImage src={OGAssets.bkgEiffel.image} onClick={() => updateImageArray('bkgEiffel')} alt="Paris" />
              <TraitImage src={OGAssets.bkgLiberty.image} onClick={() => updateImageArray('bkgLiberty')} alt="NYC" />
              <TraitImage src={OGAssets.bkgBigBen.image} onClick={() => updateImageArray('bkgBigBen')} alt="London" />
              <TraitImage src={OGAssets.bkgHongKong.image} onClick={() => updateImageArray('bkgHongKong')} alt="Hong Kong" />
              <TraitImage src={OGAssets.NoneCheckboard.image} onClick={() => updateImageArray('bkgoverlayNone')} alt="No City" />

        {showBaseTraits && (
          <div className="section-header"><h2>Base Skin</h2></div>
          )}
          {showFemaleBase && (
            <div>
              <TraitImage src={OGAssets.Female1.image} onClick={() => updateImageArray('Female1')} alt="Female 1" />
              <TraitImage src={OGAssets.Female2.image} onClick={() => updateImageArray('Female2')} alt="Female 2" />
              <TraitImage src={OGAssets.Female3.image} onClick={() => updateImageArray('Female3')} alt="Female 3" />
              <TraitImage src={OGAssets.Female4.image} onClick={() => updateImageArray('Female4')} alt="Female 4" />
              {!OGChecked && <TraitImage src={SpecialImageAssets.fxZombie.image} onClick={() => updateImageArray('fxZombie')} alt="Zombie" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fxApe.image} onClick={() => updateImageArray('fxApe')} alt="Ape" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fxAlien.image} onClick={() => updateImageArray('fxAlien')} alt="Alien" />}
              </div>
          )}
          {showMaleBase && (
            <div>
              <TraitImage src={OGAssets.Male1.image} onClick={() => updateImageArray('Male1')} alt="Male 1" />
              <TraitImage src={OGAssets.Male2.image} onClick={() => updateImageArray('Male2')} alt="Male 2" />
              <TraitImage src={OGAssets.Male3.image} onClick={() => updateImageArray('Male3')} alt="Male 3" />
              <TraitImage src={OGAssets.Male4.image} onClick={() => updateImageArray('Male4')} alt="Male 4" />
              <TraitImage src={OGAssets.Zombie.image} onClick={() => updateImageArray('Zombie')} alt="Zombie" />
              <TraitImage src={OGAssets.Ape.image} onClick={() => updateImageArray('Ape')} alt="Ape" />
              <TraitImage src={OGAssets.Alien.image} onClick={() => updateImageArray('Alien')} alt="Alien" />
              </div>
          )}

      {showFemaleTraits && (
        <div className="trait-selection-section">
          <div className="section-header"><h2>Hair</h2></div>
              {!WeldingGogglesSelected && <TraitImage src={OGAssets.fBandana.image} onClick={() => updateImageArray('fBandana')} alt="Bandana" />}
              {!WeldingGogglesSelected && <TraitImage src={OGAssets.fCap.image} onClick={() => updateImageArray('fCap')} alt="Cap" />}
              {!WeldingGogglesSelected && <TraitImage src={OGAssets.fHeadband.image} onClick={() => updateImageArray('fHeadband')} alt="Headband" />}
              {!WeldingGogglesSelected && <TraitImage src={OGAssets.fKnittedCap.image} onClick={() => updateImageArray('fKnittedCap')} alt="Knitted Cap" />}
              {!WeldingGogglesSelected && <TraitImage src={OGAssets.fPilotHelmet.image} onClick={() => updateImageArray('fPilotHelmet')} alt="Pilot Helmet" />}
              {!WeldingGogglesSelected && <TraitImage src={OGAssets.fPinkWithHat.image} onClick={() => updateImageArray('fPinkWithHat')} alt="Pink With Hat" />}
              {!WeldingGogglesSelected && <TraitImage src={OGAssets.fTassleHat.image} onClick={() => updateImageArray('fTassleHat')} alt="Tassle Hat" />}
              {!WeldingGogglesSelected && <TraitImage src={OGAssets.fTiara.image} onClick={() => updateImageArray('fTiara')} alt="Tiara" />}
              <TraitImage src={OGAssets.fBlondeBob.image} onClick={() => updateImageArray('fBlondeBob')} alt="Blonde Bob" />
              <TraitImage src={OGAssets.fBlondeShort.image} onClick={() => updateImageArray('fBlondeShort')} alt="Blonde Short" />
              <TraitImage src={OGAssets.fClownHairGreen.image} onClick={() => updateImageArray('fClownHairGreen')} alt="Clown Hair Green" />
              <TraitImage src={OGAssets.fCrazyHair.image} onClick={() => updateImageArray('fCrazyHair')} alt="Crazy Hair" />
              <TraitImage src={OGAssets.fDarkHair.image} onClick={() => updateImageArray('fDarkHair')} alt="Dark Hair" />
              <TraitImage src={OGAssets.fFrumpyHair.image} onClick={() => updateImageArray('fFrumpyHair')} alt="Frumpy Hair" />
              <TraitImage src={OGAssets.fHalfShaved.image} onClick={() => updateImageArray('fHalfShaved')} alt="Half Shaved" />
              <TraitImage src={OGAssets.fMessyHair.image} onClick={() => updateImageArray('fMessyHair')} alt="Messy Hair" />
              <TraitImage src={OGAssets.fMohawk.image} onClick={() => updateImageArray('fMohawk')} alt="Mohawk" />
              <TraitImage src={OGAssets.fMohawkDark.image} onClick={() => updateImageArray('fMohawkDark')} alt="Mohawk Dark" />
              <TraitImage src={OGAssets.fMohawkThin.image} onClick={() => updateImageArray('fMohawkThin')} alt="Mohawk Thin" />
              <TraitImage src={OGAssets.fOrangeSide.image} onClick={() => updateImageArray('fOrangeSide')} alt="Orange Side" />
              <TraitImage src={OGAssets.fPigtails.image} onClick={() => updateImageArray('fPigtails')} alt="Pigtails" />
              <TraitImage src={OGAssets.fRedMohawk.image} onClick={() => updateImageArray('fRedMohawk')} alt="Red Mohawk" />
              <TraitImage src={OGAssets.fStraightHair.image} onClick={() => updateImageArray('fStraightHair')} alt="Straight Hair" />
              <TraitImage src={OGAssets.fStraightHairBlonde.image} onClick={() => updateImageArray('fStraightHairBlonde')} alt="Straight Hair Blonde" />
              <TraitImage src={OGAssets.fStraightHairDark.image} onClick={() => updateImageArray('fStraightHairDark')} alt="Straight Hair Dark" />
              <TraitImage src={OGAssets.fStringyHair.image} onClick={() => updateImageArray('fStringyHair')} alt="Stringy Hair" />
              <TraitImage src={OGAssets.fWildBlonde.image} onClick={() => updateImageArray('fWildBlonde')} alt="Wild Blonde" />
              <TraitImage src={OGAssets.fWildHair.image} onClick={() => updateImageArray('fWildHair')} alt="Wild Hair" />
              <TraitImage src={OGAssets.fWildWhiteHair.image} onClick={() => updateImageArray('fWildWhiteHair')} alt="Wild White Hair" />

              {!OGChecked && !WeldingGogglesSelected && <TraitImage src={SpecialImageAssets.fxBeanie.image} onClick={() => updateImageArray('fxBeanie')} alt="Beanie" />}
              {!OGChecked && !WeldingGogglesSelected && <TraitImage src={SpecialImageAssets.fxCapForward.image} onClick={() => updateImageArray('fxCapForward')} alt="Cap Forward" />}
              {!OGChecked && !WeldingGogglesSelected && <TraitImage src={SpecialImageAssets.fxCowboyHat.image} onClick={() => updateImageArray('fxCowboyHat')} alt="Cowboy Hat" />}
              {!OGChecked && !WeldingGogglesSelected && <TraitImage src={SpecialImageAssets.fxFedora.image} onClick={() => updateImageArray('fxFedora')} alt="Fedora" />}
              {!OGChecked && !WeldingGogglesSelected && <TraitImage src={SpecialImageAssets.fxHoodie.image} onClick={() => updateImageArray('fxHoodie')} alt="Hoodie" />}
              {!OGChecked && !WeldingGogglesSelected && <TraitImage src={SpecialImageAssets.fxPoliceCap.image} onClick={() => updateImageArray('fxPoliceCap')} alt="Police Cap" />}
              {!OGChecked && !WeldingGogglesSelected && <TraitImage src={SpecialImageAssets.fxTopHat.image} onClick={() => updateImageArray('fxTopHat')} alt="Top Hat" />}

              {!OGChecked && !WeldingGogglesSelected && <TraitImage src={SpecialImageAssets.fMcDeesCap.image} onClick={() => updateImageArray('fMcDeesCap')} alt="Down Bad M Cap" />}
              {!OGChecked && !WeldingGogglesSelected && <TraitImage src={SpecialImageAssets.fInNOutHat.image} onClick={() => updateImageArray('fInNOutHat')} alt="Down Bad InOut Hat" />}

              {!OGChecked && !WeldingGogglesSelected && <TraitImage src={SpecialImageAssets.fBlack_CP_Cap.image} onClick={() => updateImageArray('fBlack_CP_Cap')} alt="Black CP Cap" />}
              {!OGChecked && !WeldingGogglesSelected && <TraitImage src={SpecialImageAssets.fRed_Cap_Reversed.image} onClick={() => updateImageArray('fRed_Cap_Reversed')} alt="Red Cap Reversed" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fGenuine_Mohawk_Pink.image} onClick={() => updateImageArray('fGenuine_Mohawk_Pink')} alt="Spiky Pink" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fGenuine_Mohawk_Red.image} onClick={() => updateImageArray('fGenuine_Mohawk_Red')} alt="Spiky Red" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fWSB.image} onClick={() => updateImageArray('fWSB')} alt="WSB" />}
              {/*<TraitImage src={SpecialImageAssets.fBob_black.image} onClick={() => updateImageArray('fBob_black')} alt="Black Bob" />
              <TraitImage src={SpecialImageAssets.fBob_white.image} onClick={() => updateImageArray('fBob_white')} alt="White Bob" />
              <TraitImage src={SpecialImageAssets.fBob_blonde.image} onClick={() => updateImageArray('fBob_blonde')} alt="Pink Bob" />
              <TraitImage src={SpecialImageAssets.fBob_brown.image} onClick={() => updateImageArray('fBob_brown')} alt="Brown Bob" />
              <TraitImage src={SpecialImageAssets.fBun_black.image} onClick={() => updateImageArray('fBun_black')} alt="Black Bun" />
              <TraitImage src={SpecialImageAssets.fBun_white.image} onClick={() => updateImageArray('fBun_white')} alt="White Bun" />
              <TraitImage src={SpecialImageAssets.fBun_blonde.image} onClick={() => updateImageArray('fBun_blonde')} alt="Blonde Bun" />
              <TraitImage src={SpecialImageAssets.fBun_brown.image} onClick={() => updateImageArray('fBun_brown')} alt="Brown Bun" />*/}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fFringe_black.image} onClick={() => updateImageArray('fFringe_black')} alt="Black Fringe" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fFringe_white.image} onClick={() => updateImageArray('fFringe_white')} alt="White Fringe" />}
              {/*<TraitImage src={SpecialImageAssets.fFringe_blonde.image} onClick={() => updateImageArray('fFringe_blonde')} alt="Blonde Fringe" />*/}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fFringe_brown.image} onClick={() => updateImageArray('fFringe_brown')} alt="Brown Fringe" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fPonytail_black.image} onClick={() => updateImageArray('fPonytail_black')} alt="Black Ponytail" />}
              {/*<TraitImage src={SpecialImageAssets.fPonytail_white.image} onClick={() => updateImageArray('fPonytail_white')} alt="White Ponytail" />*/}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fPonytail_blonde.image} onClick={() => updateImageArray('fPonytail_blonde')} alt="Blonde Ponytail" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fPonytail_brown.image} onClick={() => updateImageArray('fPonytail_brown')} alt="Brown Ponytail" />}
              {/*<TraitImage src={SpecialImageAssets.fShort_black.image} onClick={() => updateImageArray('fShort_black')} alt="Short Black" />
              <TraitImage src={SpecialImageAssets.fShort_white.image} onClick={() => updateImageArray('fShort_white')} alt="Short White" />
              <TraitImage src={SpecialImageAssets.fShort_blonde.image} onClick={() => updateImageArray('fShort_blonde')} alt="Short Blonde" />
              <TraitImage src={SpecialImageAssets.fShort_brown.image} onClick={() => updateImageArray('fShort_brown')} alt="Short Brown" />*/}

              <TraitImage src={OGAssets.NoneCheckboard.image} onClick={() => updateImageArray('hairNone')} alt="None" />


          <div className="section-header"><h2>Eyes</h2></div>
            {!pilotHelmetSelected && (
              <div>
              <TraitImage src={OGAssets.fBlueEyeShadow.image} onClick={() => updateImageArray('fBlueEyeShadow')} alt="Blue Eye Shadow" />
              <TraitImage src={OGAssets.fGreenEyeShadow.image} onClick={() => updateImageArray('fGreenEyeShadow')} alt="GreenEyeShadow" />
              <TraitImage src={OGAssets.fPurpleEyeShadow.image} onClick={() => updateImageArray('fPurpleEyeShadow')} alt="PurpleEyeShadow" />
              <TraitImage src={OGAssets.fClownEyesBlue.image} onClick={() => updateImageArray('fClownEyesBlue')} alt="ClownEyesBlue" />
              <TraitImage src={OGAssets.fClownEyesGreen.image} onClick={() => updateImageArray('fClownEyesGreen')} alt="ClownEyesGreen" />
              <TraitImage src={OGAssets.fEyePatch.image} onClick={() => updateImageArray('fEyePatch')} alt="EyePatch" />
              <TraitImage src={OGAssets.fEyeMask.image} onClick={() => updateImageArray('fEyeMask')} alt="EyeMask" />
              <TraitImage src={OGAssets.fGlasses3D.image} onClick={() => updateImageArray('fGlasses3D')} alt="3D Glasses" />
              <TraitImage src={OGAssets.fBigShades.image} onClick={() => updateImageArray('fBigShades')} alt="Big Shades" />
              <TraitImage src={OGAssets.fClassicShades.image} onClick={() => updateImageArray('fClassicShades')} alt="ClassicShades" />
              <TraitImage src={OGAssets.fHornedRimGlasses.image} onClick={() => updateImageArray('fHornedRimGlasses')} alt="HornedRimGlasses" />
              <TraitImage src={OGAssets.fNerdGlasses.image} onClick={() => updateImageArray('fNerdGlasses')} alt="NerdGlasses" />
              <TraitImage src={OGAssets.fRegularShades.image} onClick={() => updateImageArray('fRegularShades')} alt="Regular Shades" />
              <TraitImage src={OGAssets.fVR.image} onClick={() => updateImageArray('fVR')} alt="VR" />
              <TraitImage src={OGAssets.fWeldingGoggles.image} onClick={() => updateImageArray('fWeldingGoggles')} alt="WeldingGoggles" />


              {!OGChecked && <TraitImage src={SpecialImageAssets.fAppleVR.image} onClick={() => updateImageArray('fAppleVR')} alt="Apple Vision Pro" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fBTCglasses.image} onClick={() => updateImageArray('fBTCglasses')} alt="BTC Shades" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fPurpleHearts.image} onClick={() => updateImageArray('fPurpleHearts')} alt="Purple Heart Shades" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fNouns.image} onClick={() => updateImageArray('fNouns')} alt="Nouns Glasses" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fARglass.image} onClick={() => updateImageArray('fARglass')} alt="AR Glass" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fElton.image} onClick={() => updateImageArray('fElton')} alt="Elton Shades" />}
              {/*<TraitImage src={SpecialImageAssets.fHalfFrameBlack.image} onClick={() => updateImageArray('fHalfFrameBlack')} alt="Half Frame Black" />*/}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fHalfFrameGreen.image} onClick={() => updateImageArray('fHalfFrameGreen')} alt="Half Frame Green" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fHalfFrameThin.image} onClick={() => updateImageArray('fHalfFrameThin')} alt="Half Frame Thin" />}
              {/*<TraitImage src={SpecialImageAssets.fOval.image} onClick={() => updateImageArray('fOval')} alt="Oval" />*/}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fCybervisor.image} onClick={() => updateImageArray('fCybervisor')} alt="Cybervisor" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.fRoundGlasses.image} onClick={() => updateImageArray('fRoundGlasses')} alt="Round Glasses" />}

              <TraitImage src={OGAssets.NoneCheckboard.image} onClick={() => updateImageArray('eyesNone')} alt="None" />
              </div>
            )}

          <div className="section-header"><h2>Others</h2></div>
              <TraitImage src={OGAssets.fBlackLipstick.image} onClick={() => updateImageArray('fBlackLipstick')} alt="Black Lipstick" />
              <TraitImage src={OGAssets.fHotLipstick.image} onClick={() => updateImageArray('fHotLipstick')} alt="Hot Lipstick" />
              <TraitImage src={OGAssets.fPurpleLipstick.image} onClick={() => updateImageArray('fPurpleLipstick')} alt="Purple Lipstick" />
              <TraitImage src={OGAssets.Cigarette.image} onClick={() => updateImageArray('Cigarette')} alt="Cigarette" />
              <TraitImage src={OGAssets.Pipe.image} onClick={() => updateImageArray('Pipe')} alt="Pipe" />
              <TraitImage src={OGAssets.Vape.image} onClick={() => updateImageArray('Vape')} alt="Vape" />
              <TraitImage src={OGAssets.MedicalMaskFemale.image} onClick={() => updateImageArray('MedicalMaskFemale')} alt="Medical Mask" />

              {!OGChecked && <TraitImage src={SpecialImageAssets.Bubblegum.image} onClick={() => updateImageArray('Bubblegum')} alt="Bubblegum" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.Whistle.image} onClick={() => updateImageArray('Whistle')} alt="Whistle" />}

              <TraitImage src={OGAssets.fMole.image} onClick={() => updateImageArray('fMole')} alt="Mole" />
              <TraitImage src={OGAssets.fRosyCheeks.image} onClick={() => updateImageArray('fRosyCheeks')} alt="Rosy Cheeks" />
              <TraitImage src={OGAssets.fSpots.image} onClick={() => updateImageArray('fSpots')} alt="Spots" />
              <TraitImage src={OGAssets.fEarring.image} onClick={() => updateImageArray('fEarring')} alt="Earring" />
              <TraitImage src={OGAssets.fChoker.image} onClick={() => updateImageArray('fChoker')} alt="Choker" />
              <TraitImage src={OGAssets.fGoldChain.image} onClick={() => updateImageArray('fGoldChain')} alt="Gold Chain" />
              <TraitImage src={OGAssets.fSilverChain.image} onClick={() => updateImageArray('fSilverChain')} alt="Silver Chain" />
              <TraitImage src={OGAssets.fClownNose.image} onClick={() => updateImageArray('fClownNose')} alt="Clown Nose" />
              <TraitImage src={OGAssets.NoneCheckboard.image} onClick={() => updateImageArray('othersNone')} alt="None" />
        </div>
        )}

      {showMaleTraits && (
        <div className="trait-selection-section">
          <div className="section-header"><h2>Hair</h2></div>
              <TraitImage src={OGAssets.mBandana.image} onClick={() => updateImageArray('mBandana')} alt="Bandana" />
              <TraitImage src={OGAssets.mBeanie.image} onClick={() => updateImageArray('mBeanie')} alt="Beanie" />
              <TraitImage src={OGAssets.mCap.image} onClick={() => updateImageArray('mCap')} alt="Cap" />
              <TraitImage src={OGAssets.mCapForward.image} onClick={() => updateImageArray('mCapForward')} alt="Cap Forward" />
              <TraitImage src={OGAssets.mCowboyHat.image} onClick={() => updateImageArray('mCowboyHat')} alt="Cowboy Hat" />
              <TraitImage src={OGAssets.mDoRag.image} onClick={() => updateImageArray('mDoRag')} alt="Do-Rag" />
              <TraitImage src={OGAssets.mFedora.image} onClick={() => updateImageArray('mFedora')} alt="Fedora" />
              <TraitImage src={OGAssets.mHeadband.image} onClick={() => updateImageArray('mHeadband')} alt="Headband" />
              <TraitImage src={OGAssets.mHoodie.image} onClick={() => updateImageArray('mHoodie')} alt="Hoodie" />
              <TraitImage src={OGAssets.mKnittedCap.image} onClick={() => updateImageArray('mKnittedCap')} alt="Knitted Cap" />
              <TraitImage src={OGAssets.mPoliceCap.image} onClick={() => updateImageArray('mPoliceCap')} alt="Police Cap" />
              <TraitImage src={OGAssets.mTopHat.image} onClick={() => updateImageArray('mTopHat')} alt="Top Hat" />
              {!apeAlienSelected && <TraitImage src={OGAssets.mClownHairGreen.image} onClick={() => updateImageArray('mClownHairGreen')} alt="Clown Hair Green" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mCrazyHair.image} onClick={() => updateImageArray('mCrazyHair')} alt="Crazy Hair" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mFrumpyHair.image} onClick={() => updateImageArray('mFrumpyHair')} alt="Frumpy Hair" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mMessyHair.image} onClick={() => updateImageArray('mMessyHair')} alt="Messy Hair" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mMohawk.image} onClick={() => updateImageArray('mMohawk')} alt="Mohawk" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mMohawkDark.image} onClick={() => updateImageArray('mMohawkDark')} alt="Mohawk Dark" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mMohawkThin.image} onClick={() => updateImageArray('mMohawkThin')} alt="Mohawk Thin" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mPeakSpike.image} onClick={() => updateImageArray('mPeakSpike')} alt="Peak Spike" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mPurpleHair.image} onClick={() => updateImageArray('mPurpleHair')} alt="Purple Hair" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mShavedHead.image} onClick={() => updateImageArray('mShavedHead')} alt="Shaved Head" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mStringyHair.image} onClick={() => updateImageArray('mStringyHair')} alt="Stringy Hair" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mVampireHair.image} onClick={() => updateImageArray('mVampireHair')} alt="Vampire Hair" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mWildHair.image} onClick={() => updateImageArray('mWildHair')} alt="Wild Hair" />}

              {!OGChecked && <TraitImage src={SpecialImageAssets.mMcDeesCap.image} onClick={() => updateImageArray('mMcDeesCap')} alt="Down Bad M Cap" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.mInNOutHat.image} onClick={() => updateImageArray('mInNOutHat')} alt="Down Bad InOut Hat" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.mBlackCPCap.image} onClick={() => updateImageArray('mBlackCPCap')} alt="Black CP Cap" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.mRedCapReversed.image} onClick={() => updateImageArray('mRedCapReversed')} alt="Red Cap Reversed" />}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mGenuineMohawkPink.image} onClick={() => updateImageArray('mGenuineMohawkPink')} alt="Spiky Pink" />}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mGenuineMohawkRed.image} onClick={() => updateImageArray('mGenuineMohawkRed')} alt="Spiky Red" />}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mWSB.image} onClick={() => updateImageArray('mWSB')} alt="WSB" />}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mAfro.image} onClick={() => updateImageArray('mAfro')} alt="Afro" />}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mBecks.image} onClick={() => updateImageArray('mBecks')} alt="Becks" />}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mCenterParting.image} onClick={() => updateImageArray('mCenterParting')} alt="Centre Parting" />}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mSideflop.image} onClick={() => updateImageArray('mSideflop')} alt="Side Flop" />}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mChairman.image} onClick={() => updateImageArray('mChairman')} alt="Chairman" />}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mEdwardian.image} onClick={() => updateImageArray('mEdwardian')} alt="Edwardian" />}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mGeorgian.image} onClick={() => updateImageArray('mGeorgian')} alt="Georgian" />}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mElvis.image} onClick={() => updateImageArray('mElvis')} alt="Elvis" />}
              {/*!apeAlienSelected && <TraitImage src={SpecialImageAssets.mKPop.image} onClick={() => updateImageArray('mKPop')} alt="K-Pop" />*/}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mLongBlonde.image} onClick={() => updateImageArray('mLongBlonde')} alt="Long Blonde" />}
              {/*!apeAlienSelected && <TraitImage src={SpecialImageAssets.mShaggyBlack.image} onClick={() => updateImageArray('mShaggyBlack')} alt="Shaggy Black" />}
              {!apeAlienSelected && <TraitImage src={SpecialImageAssets.mShaggyBlonde.image} onClick={() => updateImageArray('mShaggyBlonde')} alt="Shaggy Blonde" />}
              {!apeAlienSelected && <TraitImage src={SpecialImageAssets.mShaggyBrown.image} onClick={() => updateImageArray('mShaggyBrown')} alt="Shaggy Brown" />}
              {!apeAlienSelected && <TraitImage src={SpecialImageAssets.mMediumBlack.image} onClick={() => updateImageArray('mMediumBlack')} alt="Medium Black" />}
              {!apeAlienSelected && <TraitImage src={SpecialImageAssets.mMediumBlonde.image} onClick={() => updateImageArray('mMediumBlonde')} alt="Medium Blonde" />}
              {!apeAlienSelected && <TraitImage src={SpecialImageAssets.mMediumBrown.image} onClick={() => updateImageArray('mMediumBrown')} alt="Medium Brown" />*/}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mShortBlack.image} onClick={() => updateImageArray('mShortBlack')} alt="Short Black" />}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mShortBlonde.image} onClick={() => updateImageArray('mShortBlonde')} alt="Short Blonde" />}
              {!OGChecked && !apeAlienSelected && <TraitImage src={SpecialImageAssets.mShortBrown.image} onClick={() => updateImageArray('mShortBrown')} alt="Short Brown" />}

              {!apeAlienSelected && <TraitImage src={OGAssets.NoneCheckboard.image} onClick={() => updateImageArray('hairNone')} alt="None" />}

          <div className="section-header"><h2>Eyes</h2></div>
              {!apeAlienSelected && <TraitImage src={OGAssets.mClownEyesBlue.image} onClick={() => updateImageArray('mClownEyesBlue')} alt="Clown Eyes Blue" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mClownEyesGreen.image} onClick={() => updateImageArray('mClownEyesGreen')} alt="Clown Eyes Green" />}
              <TraitImage src={OGAssets.mEyePatch.image} onClick={() => updateImageArray('mEyePatch')} alt="Eye Patch" />
              <TraitImage src={OGAssets.mEyeMask.image} onClick={() => updateImageArray('mEyeMask')} alt="Eye Mask" />
              <TraitImage src={OGAssets.mGlasses3D.image} onClick={() => updateImageArray('mGlasses3D')} alt="3D Glasses" />
              <TraitImage src={OGAssets.mBigShades.image} onClick={() => updateImageArray('mBigShades')} alt="Big Shades" />
              <TraitImage src={OGAssets.mClassicShades.image} onClick={() => updateImageArray('mClassicShades')} alt="Classic Shades" />
              <TraitImage src={OGAssets.mHornedRimGlasses.image} onClick={() => updateImageArray('mHornedRimGlasses')} alt="Horned Rim Glasses" />
              <TraitImage src={OGAssets.mNerdGlasses.image} onClick={() => updateImageArray('mNerdGlasses')} alt="Nerd Glasses" />
              <TraitImage src={OGAssets.mRegularShades.image} onClick={() => updateImageArray('mRegularShades')} alt="Regular Shades" />
              <TraitImage src={OGAssets.mSmallShades.image} onClick={() => updateImageArray('mSmallShades')} alt="Small Shades" />
              <TraitImage src={OGAssets.mVR.image} onClick={() => updateImageArray('mVR')} alt="VR" />

              {!OGChecked && <TraitImage src={SpecialImageAssets.mAppleVR.image} onClick={() => updateImageArray('mAppleVR')} alt="Apple Vision Pro" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.mBTCglasses.image} onClick={() => updateImageArray('mBTCglasses')} alt="BTC Shades" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.mPurpleHearts.image} onClick={() => updateImageArray('mPurpleHearts')} alt="Purple Heart Shades" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.mNouns.image} onClick={() => updateImageArray('mNouns')} alt="Nouns Glasses" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.mARglass.image} onClick={() => updateImageArray('mARglass')} alt="AR Glass" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.mElton.image} onClick={() => updateImageArray('mElton')} alt="Elton Shades" />}
              {/*<TraitImage src={SpecialImageAssets.mHalfFrameBlack.image} onClick={() => updateImageArray('mHalfFrameBlack')} alt="Half Frame Black" />*/}
              {!OGChecked && <TraitImage src={SpecialImageAssets.mHalfFrameGreen.image} onClick={() => updateImageArray('mHalfFrameGreen')} alt="Half Frame Green" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.mHalfFrameThin.image} onClick={() => updateImageArray('mHalfFrameThin')} alt="Half Frame Thin" />}
              {/*<TraitImage src={SpecialImageAssets.mOval.image} onClick={() => updateImageArray('mOval')} alt="Oval" />*/}
              {!OGChecked && <TraitImage src={SpecialImageAssets.mCybervisor.image} onClick={() => updateImageArray('mCybervisor')} alt="Cybervisor" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.mRoundGlasses.image} onClick={() => updateImageArray('mRoundGlasses')} alt="Round Glasses" />}

              <TraitImage src={OGAssets.NoneCheckboard.image} onClick={() => updateImageArray('eyesNone')} alt="None" />

          <div className="section-header"><h2>Facial Hair</h2></div>
            {!apeAlienSelected && (
              <div>
              <TraitImage src={OGAssets.mBigBeard.image} onClick={() => updateImageArray('mBigBeard')} alt="Big Beard" />
              <TraitImage src={OGAssets.mChinstrap.image} onClick={() => updateImageArray('mChinstrap')} alt="Chinstrap" />
              <TraitImage src={OGAssets.mFrontBeard.image} onClick={() => updateImageArray('mFrontBeard')} alt="Front Beard" />
              <TraitImage src={OGAssets.mFrontBeardDark.image} onClick={() => updateImageArray('mFrontBeardDark')} alt="Front Beard Dark" />
              <TraitImage src={OGAssets.mGoat.image} onClick={() => updateImageArray('mGoat')} alt="Goat" />
              <TraitImage src={OGAssets.mHandlebars.image} onClick={() => updateImageArray('mHandlebars')} alt="Handlebars" />
              <TraitImage src={OGAssets.mLuxuriousBeard.image} onClick={() => updateImageArray('mLuxuriousBeard')} alt="Luxurious Beard" />
              <TraitImage src={OGAssets.mMustache.image} onClick={() => updateImageArray('mMustache')} alt="Mustache" />
              <TraitImage src={OGAssets.mMuttonchops.image} onClick={() => updateImageArray('mMuttonchops')} alt="Muttonchops" />
              <TraitImage src={OGAssets.mNormalBeard.image} onClick={() => updateImageArray('mNormalBeard')} alt="Normal Beard" />
              <TraitImage src={OGAssets.mNormalBeardBlack.image} onClick={() => updateImageArray('mNormalBeardBlack')} alt="Normal Beard Black" />
              <TraitImage src={OGAssets.mShadowBeard.image} onClick={() => updateImageArray('mShadowBeard')} alt="Shadow Beard" />
              <TraitImage src={OGAssets.NoneCheckboard.image} onClick={() => updateImageArray('facialhairNone')} alt="None" />
              </div>
            )}

          <div className="section-header"><h2>Others</h2></div>
              {!apeAlienSelected && <TraitImage src={OGAssets.mBuckTeeth.image} onClick={() => updateImageArray('mBuckTeeth')} alt="Buck Teeth" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mFrown.image} onClick={() => updateImageArray('mFrown')} alt="Frown" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mSmile.image} onClick={() => updateImageArray('mSmile')} alt="Smile" />}
              <TraitImage src={OGAssets.Cigarette.image} onClick={() => updateImageArray('Cigarette')} alt="Cigarette" />
              <TraitImage src={OGAssets.Pipe.image} onClick={() => updateImageArray('Pipe')} alt="Pipe" />
              <TraitImage src={OGAssets.Vape.image} onClick={() => updateImageArray('Vape')} alt="Vape" />
              <TraitImage src={OGAssets.MedicalMaskMale.image} onClick={() => updateImageArray('MedicalMaskMale')} alt="Medical Mask" />

              {!OGChecked && <TraitImage src={SpecialImageAssets.Bubblegum.image} onClick={() => updateImageArray('Bubblegum')} alt="Bubblegum" />}
              {!OGChecked && <TraitImage src={SpecialImageAssets.Whistle.image} onClick={() => updateImageArray('Whistle')} alt="Whistle" />}

              {!apeAlienSelected && <TraitImage src={OGAssets.mMole.image} onClick={() => updateImageArray('mMole')} alt="Mole" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mRosyCheeks.image} onClick={() => updateImageArray('mRosyCheeks')} alt="Rosy Cheeks" />}
              {!apeAlienSelected && <TraitImage src={OGAssets.mSpots.image} onClick={() => updateImageArray('mSpots')} alt="Spots" />}
              <TraitImage src={OGAssets.mEarring.image} onClick={() => updateImageArray('mEarring')} alt="Earring" />
              <TraitImage src={OGAssets.mGoldChain.image} onClick={() => updateImageArray('mGoldChain')} alt="Gold Chain" />
              <TraitImage src={OGAssets.mSilverChain.image} onClick={() => updateImageArray('mSilverChain')} alt="Silver Chain" />
              <TraitImage src={OGAssets.mClownNose.image} onClick={() => updateImageArray('mClownNose')} alt="Clown Nose" />
              <TraitImage src={OGAssets.NoneCheckboard.image} onClick={() => updateImageArray('othersNone')} alt="None" />

        </div>
        )}
      </div>

      </div>
      
  );
};
  
export default App;
