import { zBackground, zBase, zMouth, zBlemish, zNeck, zFacialHair, zEar, zHair, zMouthProp, zEyes, zNose, zBKGoverlay, zAnimation} from './zIndices.js';
export const SpecialImageAssets = {

fBTCglasses: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/female/fBTCglasses.png'),
    zIndex: zEyes,
    alt: "BTC Shades",
  },
  fNouns: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/female/fNouns.png'),
    zIndex: zEyes,
    alt: "Nouns Glasses",
  },
  fPurpleHearts: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/female/fPurpleHeartShades.png'),
    zIndex: zEyes,
    alt: "Purple Hearts Shades",
  },
  fAppleVR: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/female/fappleVR.png'),
    zIndex: zEyes,
    alt: "Apple Vision Pro",
  },
  mBTCglasses: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/male/mBTCglasses.png'),
    zIndex: zEyes,
    alt: "BTC Shades",
  },
  mNouns: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/male/mNouns.png'),
    zIndex: zEyes,
    alt: "Nouns Glasses",
  },
  mPurpleHearts: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/male/mPurpleHeartShades.png'),
    zIndex: zEyes,
    alt: "Purple Hearts Shades",
  },
  mAppleVR: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/male/mappleVR.png'),
    zIndex: zEyes,
    alt: "Apple Vision Pro",
  },
  Bubblegum: {
    image: require('./Assets/Traits/SpecialImageAssets/Bubblegum.png'),
    zIndex: zMouthProp,
    alt: "Bubblegum",
  },
  Whistle: {
    image: require('./Assets/Traits/SpecialImageAssets/Whistle.png'),
    zIndex: zMouthProp,
    alt: "Whistle",
  },
  fMcDeesCap: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fMcDeesCap.png'),
    zIndex: zHair,
    alt: "Downbad M Cap",
  },
  mMcDeesCap: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mMcDeesCap.png'),
    zIndex: zHair,
    alt: "Downbad M Cap",
  },
  fInNOutHat: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fIn-N-OutHat.png'),
    zIndex: zHair,
    alt: "Downbad InOut Hat",
  },
  mInNOutHat: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mIn-N-OutHat.png'),
    zIndex: zHair,
    alt: "Downbad InOut Hat",
  },

//new eye traits
  fARglass: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/female/fARGlass.png'),
    zIndex: zEyes,
    alt: "AR Glass",
  },
  fElton: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/female/fElton.png'),
    zIndex: zEyes,
    alt: "Elton Shades",
  },
  fHalfFrameBlack: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/female/fHalfFrameBlack.png'),
    zIndex: zEyes,
    alt: "Half Frame Black",
  },
  fHalfFrameGreen: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/female/fHalfFrameGreen.png'),
    zIndex: zEyes,
    alt: "Half Frame Green",
  },
  fHalfFrameThin: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/female/fHalfFrameThin.png'),
    zIndex: zEyes,
    alt: "Half Frame Thin",
  },
  fOval: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/female/fOval.png'),
    zIndex: zEyes,
    alt: "Oval Glasses",
  },
  fCybervisor: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/female/fCybervisor.png'),
    zIndex: zEyes,
    alt: "Cybervisor",
  },
  fRoundGlasses: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/female/fRoundGlasses.png'),
    zIndex: zEyes,
    alt: "Round Glasses",
  },
  mARglass: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/male/mARglass.png'),
    zIndex: zEyes,
    alt: "AR Glass",
  },
  mHalfFrameBlack: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/male/mHalfFrameBlack.png'),
    zIndex: zEyes,
    alt: "Half Frame Black",
  },
  mHalfFrameGreen: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/male/mHalfFrameGreen.png'),
    zIndex: zEyes,
    alt: "Half Frame Green",
  },
  mHalfFrameThin: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/male/mHalfFrameThin.png'),
    zIndex: zEyes,
    alt: "Half Frame Thin",
  },
  mOval: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/male/mOval.png'),
    zIndex: zEyes,
    alt: "Oval Glasses",
  },
  mCybervisor: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/male/mCybervisor.png'),
    zIndex: zEyes,
    alt: "Cybervisor",
  },
  mRoundGlasses: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/male/mRoundGlasses.png'),
    zIndex: zEyes,
    alt: "Round Glasses",
  },
  mElton: {
    image: require('./Assets/Traits/SpecialImageAssets/eyes-new/male/mElton.png'),
    zIndex: zEyes,
    alt: "Elton Shades",
  },

//New female hair traits
  fBlack_CP_Cap: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fBlack_CP_Cap.png'),
    zIndex: zHair,
    alt: "Black CP Cap",
  },
  fBob_black: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fBob_black.png'),
    zIndex: zHair,
    alt: "Black Bob",
  },
  fBob_blonde: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fBob_blonde.png'),
    zIndex: zHair,
    alt: "Blonde Bob",
  },
  fBob_brown: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fBob_brown.png'),
    zIndex: zHair,
    alt: "Brown Bob",
  },
  fBob_white: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fBob_white.png'),
    zIndex: zHair,
    alt: "White Bob",
  },
  fBun_black: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fBun_black.png'),
    zIndex: zHair,
    alt: "Black Bun",
  },
  fBun_blonde: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fBun_blonde.png'),
    zIndex: zHair,
    alt: "Blonde Bun",
  },
  fBun_brown: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fBun_brown.png'),
    zIndex: zHair,
    alt: "Brown Bun",
  },
  fBun_white: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fBun_white.png'),
    zIndex: zHair,
    alt: "White Bun",
  },
  fFringe_black: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fFringe_black.png'),
    zIndex: zHair,
    alt: "Black Fringe",
  },
  fFringe_blonde: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fFringe_blonde.png'),
    zIndex: zHair,
    alt: "Blonde Fringe",
  },
  fFringe_brown: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fFringe_brown.png'),
    zIndex: zHair,
    alt: "Brown Fringe",
  },
  fFringe_white: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fFringe_white.png'),
    zIndex: zHair,
    alt: "White Fringe",
  },
  fGenuine_Mohawk_Pink: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fGenuine_Mohawk_Pink.png'),
    zIndex: zHair,
    alt: "Genuine Spiky Pink",
  },
  fGenuine_Mohawk_Red: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fGenuine_Mohawk_Red.png'),
    zIndex: zHair,
    alt: "Genuine Spiky Red",
  },
  fPonytail_black: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fPonytail_black.png'),
    zIndex: zHair,
    alt: "Black Ponytail",
  },
  fPonytail_blonde: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fPonytail_blonde.png'),
    zIndex: zHair,
    alt: "Blonde Ponytail",
  },
  fPonytail_brown: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fPonytail_brown.png'),
    zIndex: zHair,
    alt: "Brown Ponytail",
  },
  fPonytail_white: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fPonytail_white.png'),
    zIndex: zHair,
    alt: "White Ponytail",
  },
  fRed_Cap_Reversed: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fRed_Cap_Reversed.png'),
    zIndex: zHair,
    alt: "Red Cap Reversed",
  },
  fShort_black: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fShort_black.png'),
    zIndex: zHair,
    alt: "Short Black",
  },
  fShort_blonde: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fShort_blonde.png'),
    zIndex: zHair,
    alt: "Short Blonde",
  },
  fShort_brown: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fShort_brown.png'),
    zIndex: zHair,
    alt: "Short Brown",
  },
  fShort_white: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fShort_white.png'),
    zIndex: zHair,
    alt: "Short White",
  },
  fWSB: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/fWSB.png'),
    zIndex: zHair,
    alt: "WSB",
  },

//New male hair traits
  mAfro: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mAfro.png'),
    zIndex: zHair,
    alt: "Afro",
  },
  mBecks: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mBecks.png'),
    zIndex: zHair,
    alt: "Brush it like Becks",
  },
  mBlackCPCap: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mBlackCPCap.png'),
    zIndex: zHair,
    alt: "Black CP Cap",
  },
  mCenterParting: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mCenterParting.png'),
    zIndex: zHair,
    alt: "Centre Parting",
  },
  mChairman: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mChairman.png'),
    zIndex: zHair,
    alt: "Chairman",
  },
  mEdwardian: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mEdwardian.png'),
    zIndex: zHair,
    alt: "Edwardian",
  },
  mElvis: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mElvis.png'),
    zIndex: zHair,
    alt: "Elvis",
  },
  mGenuineMohawkPink: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mGenuine_Mohawk_Pink.png'),
    zIndex: zHair,
    alt: "Genuine Spiky Pink",
  },
  mGenuineMohawkRed: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mGenuine_Mohawk_Red.png'),
    zIndex: zHair,
    alt: "Genuine Spiky Red",
  },
  mGeorgian: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mGeorgian.png'),
    zIndex: zHair,
    alt: "Georgian",
  },
  mKPop: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mKPop.png'),
    zIndex: zHair,
    alt: "K-Pop Moptop",
  },
  mLongBlonde: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mLong_Blonde.png'),
    zIndex: zHair,
    alt: "Long Blonde",
  },
  mShaggyBlack: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mShaggy_Black.png'),
    zIndex: zHair,
    alt: "Shaggy Black",
  },
  mShaggyBlonde: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mShaggy_Blonde.png'),
    zIndex: zHair,
    alt: "Shaggy Blonde",
  },
  mShaggyBrown: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mShaggy_Brown.png'),
    zIndex: zHair,
    alt: "Shaggy Brown",
  },
  mMediumBlack: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mMedium_Black.png'),
    zIndex: zHair,
    alt: "Medium Black",
  },
  mMediumBlonde: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mMedium_Blonde.png'),
    zIndex: zHair,
    alt: "Medium Blonde",
  },
  mMediumBrown: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mMedium_Brown.png'),
    zIndex: zHair,
    alt: "Medium Brown",
  },
  mRedCapReversed: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mRed_Cap_Reversed.png'),
    zIndex: zHair,
    alt: "Red Cap Reversed",
  },
  mShortBlack: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mShort_Black.png'),
    zIndex: zHair,
    alt: "Short Black",
  },
  mShortBlonde: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mShort_Blonde.png'),
    zIndex: zHair,
    alt: "Short Blonde",
  },
  mShortBrown: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mShort_Brown.png'),
    zIndex: zHair,
    alt: "Short Brown",
  },
  mSideflop: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mSideflop.png'),
    zIndex: zHair,
    alt: "Side Flop",
  },
  mWSB: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/male/mWSB.png'),
    zIndex: zHair,
    alt: "WSB",
  },


//Expansion Punks Traits
  fxAlien: {
    image: require('./Assets/Traits/SpecialImageAssets/base-new/female/xAlien_Female.png'),
    zIndex: zBase,
    alt: "xAlien",
  },
  fxApe: {
    image: require('./Assets/Traits/SpecialImageAssets/base-new/female/xApe_Female.png'),
    zIndex: zBase,
    alt: "xApe",
  },
  fxZombie: {
    image: require('./Assets/Traits/SpecialImageAssets/base-new/female/xZombie_Female.png'),
    zIndex: zBase,
    alt: "xZombie",
  },
  fxBeanie: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/xBeanie_Female.png'),
    zIndex: zHair,
    alt: "Beanie",
  },
  fxCapForward: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/xCapForward_Female.png'),
    zIndex: zHair,
    alt: "Cap Forward",
  },
  fxCowboyHat: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/xCowboyHat_Female.png'),
    zIndex: zHair,
    alt: "Cowboy Hat",
  },
  fxFedora: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/xFedora_Female.png'),
    zIndex: zHair,
    alt: "Fedora",
  },
  fxHoodie: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/xHoodie_Female.png'),
    zIndex: zHair,
    alt: "Hoodie",
  },
  fxPoliceCap: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/xPoliceCap_Female.png'),
    zIndex: zHair,
    alt: "Police Cap",
  },
  fxTopHat: {
    image: require('./Assets/Traits/SpecialImageAssets/hair-new/female/xTopHat_Female.png'),
    zIndex: zHair,
    alt: "TopHat",
  },

//Animated Template Gifs
  GMpaint: {
    image: require('./Assets/Animations/0-TEMPLATE/GM-paint.gif'),
    zIndex: zAnimation,
    alt: "GM paint",
  },
  GMspray: {
    image: require('./Assets/Animations/0-TEMPLATE/GM-spraycan.gif'),
    zIndex: zAnimation,
    alt: "GM spraycan",
  },
  GMunroll: {
    image: require('./Assets/Animations/0-TEMPLATE/GM-unroll.gif'),
    zIndex: zAnimation,
    alt: "GM unroll",
  },
  Heart: {
    image: require('./Assets/Animations/0-TEMPLATE/Heart.gif'),
    zIndex: zAnimation,
    alt: "Heart",
  },
  ThumbsUp: {
    image: require('./Assets/Animations/0-TEMPLATE/ThumbsUp-shaded-male2.gif'),
    zIndex: zAnimation,
    alt: "Thumbs Up",
  },
}