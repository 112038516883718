import React, { useState } from 'react';

const TraitImage = ({ src, alt, onClick }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  const handleMouseEnter = () => {
    setShowOverlay(true);
  };

  const handleMouseLeave = () => {
    setShowOverlay(false);
  };

  return (
    <div className="trait-image-container">
      <img
        src={src}
        alt={alt}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={onClick}
      />
      {showOverlay && <div className="overlay">{alt}</div>}
    </div>
  );
};

export default TraitImage;