import React, { useState, useEffect, useRef } from 'react';

const BuildCanvas = ({ layers, width, height }) => {
  const canvasRef = useRef(null);
  const [redrawTrigger, setRedrawTrigger] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    context.imageSmoothingEnabled = false;

    // Clear the canvas
    context.clearRect(0, 0, width, height);

    console.log('canvas.js is called');

    // Draw the layers in reverse order
    for (let i = layers.length - 1; i >= 0; i--) {
      const layer = layers[i];
      if (layer) {
        const image = new Image();
        image.src = layer.image;
        image.onload = () => {
          context.drawImage(image, 0, 0, width, height);
        };
      }
    }
  }, [layers, width, height, redrawTrigger]);

  useEffect(() => {
    // Trigger redraw when layers change
    setRedrawTrigger(prev => !prev);
  }, [layers]);

  return <canvas ref={canvasRef} width={width} height={height} />;
};

export default BuildCanvas;
