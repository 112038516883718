import React, { useState } from 'react';
import twitterLogoPng from './Assets/twitterlogobluenobkg.png';
import twitterLogoGif from './Assets/twitterlogobluenobkg.gif';

const TwitterLink = () => {
  const [isGif, setIsGif] = useState(false);

  const changeLogoToGif = () => {
    setIsGif(true);
  };

  const changeLogoToPng = () => {
    setIsGif(false);
  };

  return (
    <a href="https://twitter.com/ThePunkAnimator" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', verticalAlign: 'middle' }}>
      <img
        id="twitterlogo"
        src={isGif ? twitterLogoGif : twitterLogoPng}
        alt="Twitter"
        onMouseOver={changeLogoToGif}
        onMouseOut={changeLogoToPng}
        className="twitter-logo"
        style={{ imageRendering: 'pixelated'}}
      />
    </a>
  );
};

export default TwitterLink;
