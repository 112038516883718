import { zBackground, zBase, zMouth, zBlemish, zNeck, zFacialHair, zEar, zHair, zMouthProp, zEyes, zNose, zBKGoverlay} from './zIndices.js';

export const OGAssets = {

      forcedDraw: {
        image: require('./Assets/Traits/background/none.png'),
        zIndex: 0,
        alt: "NULL",
      },
      OG: {
        image: require('./Assets/Traits/unshaded/background/Background.png'),
        zIndex: zBackground,
        alt: "OG",
      },
      V1: {
        image: require('./Assets/Traits/unshaded/background/Background_V1.png'),
        zIndex: zBackground,
        alt: "V1",
      },
      OGV1: {
        image: require('./Assets/Traits/unshaded/background/Background_V1split.png'),
        zIndex: zBackground,
        alt: "OG & V1 legendary pair",
      },
      BTC: {
        image: require('./Assets/Traits/unshaded/background/Background_BTC.png'),
        zIndex: zBackground,
        alt: "Bitcoin Punks",
      },
      SOL: {
        image: require('./Assets/Traits/unshaded/background/Background_solana.png'),
        zIndex: zBackground,
        alt: "Solpunks",
      },
      xPunk: {
        image: require('./Assets/Traits/unshaded/background/Background_xPunk.png'),
        zIndex: zBackground,
        alt: "ExpansionPunks",
      },
      Gradient: {
        image: require('./Assets/Traits/unshaded/background/Background_Gradient.png'),
        zIndex: zBackground,
        alt: "Gradient",
      },
      White: {
        image: require('./Assets/Traits/unshaded/background/Background_White.png'),
        zIndex: zBackground,
        alt: "White",
      },
      bkgNone: {
        image: require('./Assets/Traits/background/none.png'),
        zIndex: zBackground,
        alt: "None",
      },
      NoneCheckboard: {
        image: require('./Assets/Traits/unshaded/background/NoneCheckboard.png'),
        zIndex: zBackground,
      },

      // Background city traits...
      bkgEiffel: {
        image: require('./Assets/Traits/unshaded/background/BKG-Eiffel.png'),
        zIndex: zBKGoverlay,
        alt: "Eiffel Tower",
      },
      bkgLiberty: {
        image: require('./Assets/Traits/unshaded/background/BKG-Liberty.png'),
        zIndex: zBKGoverlay,
        alt: "Statue of Liberty",
      },
      bkgBigBen: {
        image: require('./Assets/Traits/unshaded/background/BKG-BigBen.png'),
        zIndex: zBKGoverlay,
        alt: "Big Ben",
      },
      bkgHongKong: {
        image: require('./Assets/Traits/unshaded/background/BKG-HongKong.png'),
        zIndex: zBKGoverlay,
        alt: "Hong Kong",
      },


      bkgoverlayNone: {
        image: require('./Assets/Traits/background/none.png'),
        zIndex: zBKGoverlay,
        alt: "None",
      },
      // Other background traits...
    

        Female1: {
          image: require('./Assets/Traits/unshaded/base/Female1.png'),
          zIndex: zBase,
          alt: "Female 1",
        },
        Female2: {
          image: require('./Assets/Traits/unshaded/base/Female2.png'),
          zIndex: zBase,
          alt: "Female 2",
        },
        Female3: {
          image: require('./Assets/Traits/unshaded/base/Female3.png'),
          zIndex: zBase,
          alt: "Female 3",
        },
        Female4: {
          image: require('./Assets/Traits/unshaded/base/Female4.png'),
          zIndex: zBase,
          alt: "Female 4",
        },
        // Other female base traits...

        Male1: {
          image: require('./Assets/Traits/unshaded/base/Male1.png'),
          zIndex: zBase,
          alt: "Male 1",
        },
        Male2: {
          image: require('./Assets/Traits/unshaded/base/Male2.png'),
          zIndex: zBase,
          alt: "Male 2",
        },
        Male3: {
          image: require('./Assets/Traits/unshaded/base/Male3.png'),
          zIndex: zBase,
          alt: "Male 3",
        },
        Male4: {
          image: require('./Assets/Traits/unshaded/base/Male4.png'),
          zIndex: zBase,
          alt: "Male 4",
        },
        Zombie: {
          image: require('./Assets/Traits/unshaded/base/Zombie.png'),
          zIndex: zBase,
          alt: "Zombie",
        },
        Ape: {
          image: require('./Assets/Traits/unshaded/base/Ape.png'),
          zIndex: zBase,
          alt: "Ape",
        },
        Alien: {
          image: require('./Assets/Traits/unshaded/base/Alien.png'),
          zIndex: zBase,
          alt: "Alien",
        },
        // Other male base traits...

        fBandana: {
          image: require('./Assets/Traits/unshaded/hair/female/Bandana_Female.png'),
          zIndex: zHair,
          alt: "Bandana",
        },
        fBlondeBob: {
          image: require('./Assets/Traits/unshaded/hair/female/BlondeBob.png'),
          zIndex: zHair,
          alt: "Blonde Bob",
        },
        fBlondeShort: {
          image: require('./Assets/Traits/unshaded/hair/female/BlondeShort.png'),
          zIndex: zHair,
          alt: "Blonde Short",
        },
        fCap: {
          image: require('./Assets/Traits/unshaded/hair/female/Cap_Female.png'),
          zIndex: zHair,
          alt: "Cap",
        },
        fClownHairGreen: {
          image: require('./Assets/Traits/unshaded/hair/female/ClownHairGreen_Female.png'),
          zIndex: zHair,
          alt: "Clown Hair Green",
        },
        fCrazyHair: {
          image: require('./Assets/Traits/unshaded/hair/female/CrazyHair_Female.png'),
          zIndex: zHair,
          alt: "Crazy Hair",
        },
        fDarkHair: {
          image: require('./Assets/Traits/unshaded/hair/female/DarkHair.png'),
          zIndex: zHair,
          alt: "Dark Hair",
        },
        fFrumpyHair: {
          image: require('./Assets/Traits/unshaded/hair/female/FrumpyHair_Female.png'),
          zIndex: zHair,
          alt: "Frumpy Hair",
        },
        fHalfShaved: {
          image: require('./Assets/Traits/unshaded/hair/female/HalfShaved.png'),
          zIndex: zHair,
          alt: "Half Shaved",
        },      
        fHeadband: {
          image: require('./Assets/Traits/unshaded/hair/female/Headband_Female.png'),
          zIndex: zHair,
          alt: "Headband",
        },
        fKnittedCap: {
          image: require('./Assets/Traits/unshaded/hair/female/KnittedCap_Female.png'),
          zIndex: zHair,
          alt: "Knitted Cap",
        },
        fMessyHair: {
          image: require('./Assets/Traits/unshaded/hair/female/MessyHair.png'),
          zIndex: zHair,
          alt: "Messy Hair",
        },
        fMohawkDark: {
          image: require('./Assets/Traits/unshaded/hair/female/MohawkDark_Female.png'),
          zIndex: zHair,
          alt: "Mohawk Dark",
        },
        fMohawkThin: {
          image: require('./Assets/Traits/unshaded/hair/female/MohawkThin_Female.png'),
          zIndex: zHair,
          alt: "Mohawk Thin",
        },
        fMohawk: {
          image: require('./Assets/Traits/unshaded/hair/female/Mohawk_Female.png'),
          zIndex: zHair,
          alt: "Mohawk",
        },
        fOrangeSide: {
          image: require('./Assets/Traits/unshaded/hair/female/OrangeSide.png'),
          zIndex: zHair,
          alt: "Orange Side",
        },
        fPigtails: {
          image: require('./Assets/Traits/unshaded/hair/female/Pigtails.png'),
          zIndex: zHair,
          alt: "Pigtails",
        },
        fPilotHelmet: {
          image: require('./Assets/Traits/unshaded/hair/female/PilotHelmet.png'),
          zIndex: zHair,
          alt: "Pilot Helmet",
        },
        fPinkWithHat: {
          image: require('./Assets/Traits/unshaded/hair/female/PinkWithHat.png'),
          zIndex: zHair,
          alt: "Pink With Hat",
        },
        fRedMohawk: {
          image: require('./Assets/Traits/unshaded/hair/female/RedMohawk.png'),
          zIndex: zHair,
          alt: "Red Mowhawk",
        },
        fStraightHair: {
          image: require('./Assets/Traits/unshaded/hair/female/StraightHair.png'),
          zIndex: zHair,
          alt: "Straight Hair",
        },
        fStraightHairBlonde: {
          image: require('./Assets/Traits/unshaded/hair/female/StraightHairBlonde.png'),
          zIndex: zHair,
          alt: "Straight Hair Blonde",
        },
        fStraightHairDark: {
          image: require('./Assets/Traits/unshaded/hair/female/StraightHairDark.png'),
          zIndex: zHair,
          alt: "Straight Hair Dark",
        },
        fStringyHair: {
          image: require('./Assets/Traits/unshaded/hair/female/StringyHair_Female.png'),
          zIndex: zHair,
          alt: "Stringy Hair",
        },
        fTassleHat: {
          image: require('./Assets/Traits/unshaded/hair/female/TassleHat.png'),
          zIndex: zHair,
          alt: "Tassle Hat",
        },
        fTiara: {
          image: require('./Assets/Traits/unshaded/hair/female/Tiara.png'),
          zIndex: zHair,
          alt: "Tiara",
        },
        fWildBlonde: {
          image: require('./Assets/Traits/unshaded/hair/female/WildBlonde.png'),
          zIndex: zHair,
          alt: "Wild Blonde",
        },
        fWildHair: {
          image: require('./Assets/Traits/unshaded/hair/female/WildHair_Female.png'),
          zIndex: zHair,
          alt: "Wild Hair",
        },
        fWildWhiteHair: {
          image: require('./Assets/Traits/unshaded/hair/female/WildWhiteHair.png'),
          zIndex: zHair,
          alt: "Wild White Hair",
        },
        hairNone: {
          image: require('./Assets/Traits/background/none.png'),
          zIndex: zHair,
          alt: "None",
        },
        mBandana: {
          image: require('./Assets/Traits/unshaded/hair/male/Bandana_Male.png'),
          zIndex: zHair,
          alt: "Bandana",
        },
        mBeanie: {
          image: require('./Assets/Traits/unshaded/hair/male/Beanie.png'),
          zIndex: zHair,
          alt: "Beanie",
        },
        mCapForward: {
          image: require('./Assets/Traits/unshaded/hair/male/CapForward.png'),
          zIndex: zHair,
          alt: "Cap Forward",
        },
        mCap: {
          image: require('./Assets/Traits/unshaded/hair/male/Cap_Male.png'),
          zIndex: zHair,
          alt: "Cap",
        },
        mClownHairGreen: {
          image: require('./Assets/Traits/unshaded/hair/male/ClownHairGreen_Male.png'),
          zIndex: zHair,
          alt: "Clown Hair Green",
        },
        mCowboyHat: {
          image: require('./Assets/Traits/unshaded/hair/male/CowboyHat.png'),
          zIndex: zHair,
          alt: "Cowboy Hat",
        },
        mCrazyHair: {
          image: require('./Assets/Traits/unshaded/hair/male/CrazyHair_Male.png'),
          zIndex: zHair,
          alt: "Crazy Hair",
        },
        mDoRag: {
          image: require('./Assets/Traits/unshaded/hair/male/Do-rag.png'),
          zIndex: zHair,
          alt: "Do-rag",
        },
        mFedora: {
          image: require('./Assets/Traits/unshaded/hair/male/Fedora.png'),
          zIndex: zHair,
          alt: "Fedora",
        },
        mFrumpyHair: {
          image: require('./Assets/Traits/unshaded/hair/male/FrumpyHair_Male.png'),
          zIndex: zHair,
          alt: "Frumpy Hair",
        },
        mHeadband: {
          image: require('./Assets/Traits/unshaded/hair/male/Headband_Male.png'),
          zIndex: zHair,
          alt: "Headband",
        },
        mHoodie: {
          image: require('./Assets/Traits/unshaded/hair/male/Hoodie.png'),
          zIndex: zHair,
          alt: "Hoodie",
        },
        mKnittedCap: {
          image: require('./Assets/Traits/unshaded/hair/male/KnittedCap_Male.png'),
          zIndex: zHair,
          alt: "Knitted Cap",
        },
        mMessyHair: {
          image: require('./Assets/Traits/unshaded/hair/male/MessyHair.png'),
          zIndex: zHair,
          alt: "Messy Hair",
        },
        mMohawkDark: {
          image: require('./Assets/Traits/unshaded/hair/male/MohawkDark_Male.png'),
          zIndex: zHair,
          alt: "Mohawk Dark",
        },
        mMohawkThin: {
          image: require('./Assets/Traits/unshaded/hair/male/MohawkThin_Male.png'),
          zIndex: zHair,
          alt: "Mohawk Thin",
        },
        mMohawk: {
          image: require('./Assets/Traits/unshaded/hair/male/Mohawk_Male.png'),
          zIndex: zHair,
          alt: "Mohawk",
        },
        mPeakSpike: {
          image: require('./Assets/Traits/unshaded/hair/male/PeakSpike.png'),
          zIndex: zHair,
          alt: "Peak Spike",
        },
        mPoliceCap: {
          image: require('./Assets/Traits/unshaded/hair/male/PoliceCap.png'),
          zIndex: zHair,
          alt: "Police Cap",
        },
        mPurpleHair: {
          image: require('./Assets/Traits/unshaded/hair/male/PurpleHair.png'),
          zIndex: zHair,
          alt: "Purple Hair",
        },
        mShavedHead: {
          image: require('./Assets/Traits/unshaded/hair/male/ShavedHead.png'),
          zIndex: zHair,
          alt: "Shaved Head",
        },
        mStringyHair: {
          image: require('./Assets/Traits/unshaded/hair/male/StringyHair_Male.png'),
          zIndex: zHair,
          alt: "Stringy Hair",
        },
        mTopHat: {
          image: require('./Assets/Traits/unshaded/hair/male/TopHat.png'),
          zIndex: zHair,
          alt: "Top Hat",
        },
        mVampireHair: {
          image: require('./Assets/Traits/unshaded/hair/male/VampireHair.png'),
          zIndex: zHair,
          alt: "Vampire Hair",
        },
        mWildHair: {
          image: require('./Assets/Traits/unshaded/hair/male/WildHair_Male.png'),
          zIndex: zHair,
          alt: "Wild Hair",
        },
        fGlasses3D: {
          image: require('./Assets/Traits/unshaded/eyes/female/3DGlasses_Female.png'),
          zIndex: zEyes,
          alt: "3D Glasses",
        },
        fBigShades: {
          image: require('./Assets/Traits/unshaded/eyes/female/BigShades_Female.png'),
          zIndex: zEyes,
          alt: "Big Shades",
        },
        fBlueEyeShadow: {
          image: require('./Assets/Traits/unshaded/eyes/female/BlueEyeShadow.png'),
          zIndex: zEyes,
          alt: "Blue Eye Shadow",
        },
        fClassicShades: {
          image: require('./Assets/Traits/unshaded/eyes/female/ClassicShades_Female.png'),
          zIndex: zEyes,
          alt: "Classic Shades",
        },
        fClownEyesBlue: {
          image: require('./Assets/Traits/unshaded/eyes/female/ClownEyesBlue_Female.png'),
          zIndex: zEyes,
          alt: "Clown Eyes Blue",
        },
        fClownEyesGreen: {
          image: require('./Assets/Traits/unshaded/eyes/female/ClownEyesGreen_Female.png'),
          zIndex: zEyes,
          alt: "Clown Eyes Green",
        },
        fEyeMask: {
          image: require('./Assets/Traits/unshaded/eyes/female/EyeMask_Female.png'),
          zIndex: zEyes,
          alt: "Eye Mask",
        },
        fEyePatch: {
          image: require('./Assets/Traits/unshaded/eyes/female/EyePatch_Female.png'),
          zIndex: zEyes,
          alt: "Eye Patch",
        },
        fGreenEyeShadow: {
          image: require('./Assets/Traits/unshaded/eyes/female/GreenEyeShadow.png'),
          zIndex: zEyes,
          alt: "Green Eye Shadow",
        },
        fHornedRimGlasses: {
          image: require('./Assets/Traits/unshaded/eyes/female/HornedRimGlasses_Female.png'),
          zIndex: zEyes,
          alt: "Horned Rim Glasses",
        },
        fNerdGlasses: {
          image: require('./Assets/Traits/unshaded/eyes/female/NerdGlasses_Female.png'),
          zIndex: zEyes,
          alt: "Nerd Glasses",
        },
        fPurpleEyeShadow: {
          image: require('./Assets/Traits/unshaded/eyes/female/PurpleEyeShadow.png'),
          zIndex: zEyes,
          alt: "Purple Eye Shadow",
        },
        fRegularShades: {
          image: require('./Assets/Traits/unshaded/eyes/female/RegularShades_Female.png'),
          zIndex: zEyes,
          alt: "Regular Shades",
        },
        fVR: {
          image: require('./Assets/Traits/unshaded/eyes/female/VR_Female.png'),
          zIndex: zEyes,
          alt: "VR",
        },
        fWeldingGoggles: {
          image: require('./Assets/Traits/unshaded/eyes/female/WeldingGoggles.png'),
          zIndex: zEyes,
          alt: "Welding Goggles",
        },
        mGlasses3D: {
          image: require('./Assets/Traits/unshaded/eyes/male/3DGlasses_Male.png'),
          zIndex: zEyes,
          alt: "3D Glasses",
        },
        mBigShades: {
          image: require('./Assets/Traits/unshaded/eyes/male/BigShades_Male.png'),
          zIndex: zEyes,
          alt: "Big Shades",
        },
        mClassicShades: {
          image: require('./Assets/Traits/unshaded/eyes/male/ClassicShades_Male.png'),
          zIndex: zEyes,
          alt: "Classic Shades",
        },
        mClownEyesBlue: {
          image: require('./Assets/Traits/unshaded/eyes/male/ClownEyesBlue_Male.png'),
          zIndex: zEyes,
          alt: "Clown Eyes Blue",
        },
        mClownEyesGreen: {
          image: require('./Assets/Traits/unshaded/eyes/male/ClownEyesGreen_Male.png'),
          zIndex: zEyes,
          alt: "Clown Eyes Green",
        },
        mEyeMask: {
          image: require('./Assets/Traits/unshaded/eyes/male/EyeMask_Male.png'),
          zIndex: zEyes,
          alt: "Eye Mask",
        },
        mEyePatch: {
          image: require('./Assets/Traits/unshaded/eyes/male/EyePatch_Male.png'),
          zIndex: zEyes,
          alt: "Eye Patch",
        },
        mHornedRimGlasses: {
          image: require('./Assets/Traits/unshaded/eyes/male/HornedRimGlasses_Male.png'),
          zIndex: zEyes,
          alt: "Horned Rim Glasses",
        },
        mNerdGlasses: {
          image: require('./Assets/Traits/unshaded/eyes/male/NerdGlasses_Male.png'),
          zIndex: zEyes,
          alt: "Nerd Glasses",
        },
        mRegularShades: {
          image: require('./Assets/Traits/unshaded/eyes/male/RegularShades_Male.png'),
          zIndex: zEyes,
          alt: "Regular Shades",
        },
        mSmallShades: {
          image: require('./Assets/Traits/unshaded/eyes/male/SmallShades.png'),
          zIndex: zEyes,
          alt: "Small Shades",
        },
        mVR: {
          image: require('./Assets/Traits/unshaded/eyes/male/VR_Male.png'),
          zIndex: zEyes,
          alt: "VR",
        },
        eyesNone: {
          image: require('./Assets/Traits/background/none.png'),
          zIndex: zEyes,
          alt: "None",
        },
        mBigBeard: {
          image: require('./Assets/Traits/unshaded/facial/BigBeard.png'),
          zIndex: zFacialHair,
          alt: "Big Beard",
        },
        mChinstrap: {
          image: require('./Assets/Traits/unshaded/facial/Chinstrap.png'),
          zIndex: zFacialHair,
          alt: "Chinstrap",
        },
        mFrontBeard: {
          image: require('./Assets/Traits/unshaded/facial/FrontBeard.png'),
          zIndex: zFacialHair,
          alt: "Front Beard",
        },
        mFrontBeardDark: {
          image: require('./Assets/Traits/unshaded/facial/FrontBeardDark.png'),
          zIndex: zFacialHair,
          alt: "Front Beard Dark",
        },
        mGoat: {
          image: require('./Assets/Traits/unshaded/facial/Goat.png'),
          zIndex: zFacialHair,
          alt: "Goat",
        },
        mHandlebars: {
          image: require('./Assets/Traits/unshaded/facial/Handlebars.png'),
          zIndex: zFacialHair,
          alt: "Handlebars",
        },
        mLuxuriousBeard: {
          image: require('./Assets/Traits/unshaded/facial/LuxuriousBeard.png'),
          zIndex: zFacialHair,
          alt: "Luxurious Beard",
        },
        mMustache: {
          image: require('./Assets/Traits/unshaded/facial/Mustache.png'),
          zIndex: zFacialHair,
          alt: "Mustache",
        },
        mMuttonchops: {
          image: require('./Assets/Traits/unshaded/facial/Muttonchops.png'),
          zIndex: zFacialHair,
          alt: "Muttonchops",
        },
        mNormalBeard: {
          image: require('./Assets/Traits/unshaded/facial/NormalBeard.png'),
          zIndex: zFacialHair,
          alt: "Normal Beard",
        },
        mNormalBeardBlack: {
          image: require('./Assets/Traits/unshaded/facial/NormalBeardBlack.png'),
          zIndex: zFacialHair,
          alt: "Normal Beard Black",
        },
        mShadowBeard: {
          image: require('./Assets/Traits/unshaded/facial/ShadowBeard.png'),
          zIndex: zFacialHair,
          alt: "Shadow Beard",
        },
        facialhairNone: {
          image: require('./Assets/Traits/background/none.png'),
          zIndex: zFacialHair,
          alt: "None",
        },
        // Other male facial hair traits...
      Cigarette: {
        image: require('./Assets/Traits/unshaded/other/Cigarette.png'),
        zIndex: zMouthProp,
          alt: "Cigarette",
      },
      Pipe: {
        image: require('./Assets/Traits/unshaded/other/Pipe.png'),
        zIndex: zMouthProp,
          alt: "Pipe",
      },
      Vape: {
        image: require('./Assets/Traits/unshaded/other/Vape.png'),
        zIndex: zMouthProp,
          alt: "Vape",
      },
      MedicalMaskFemale: {
        image: require('./Assets/Traits/unshaded/other/female/MedicalMask_Female.png'),
        zIndex: zMouthProp,
          alt: "Medical Mask",
      },
      MedicalMaskMale: {
        image: require('./Assets/Traits/unshaded/other/male/MedicalMask_Male.png'),
        zIndex: zMouthProp,
          alt: "Medical Mask",
      },
        fBlackLipstick: {
          image: require('./Assets/Traits/unshaded/other/female/BlackLipstick.png'),
          zIndex: zMouth,
          alt: "Black Lipstick",
        },
        fChoker: {
          image: require('./Assets/Traits/unshaded/other/female/Choker.png'),
          zIndex: zNeck,
          alt: "Choker",
        },
        fClownNose: {
          image: require('./Assets/Traits/unshaded/other/female/ClownNose_Female.png'),
          zIndex: zNose,
          alt: "Clown Nose",
        },
        fEarring: {
          image: require('./Assets/Traits/unshaded/other/female/Earring_Female.png'),
          zIndex: zEar,
          alt: "Earring",
        },
        fGoldChain: {
          image: require('./Assets/Traits/unshaded/other/female/GoldChain_Female.png'),
          zIndex: zNeck,
          alt: "Gold Chain",
        },
        fHotLipstick: {
          image: require('./Assets/Traits/unshaded/other/female/HotLipstick.png'),
          zIndex: zMouth,
          alt: "Hot Lipstick",
        },
        fMedicalMask: {
          image: require('./Assets/Traits/unshaded/other/female/MedicalMask_Female.png'),
          zIndex: zMouthProp,
          alt: "Medical Mask",
        },
        fMole: {
          image: require('./Assets/Traits/unshaded/other/female/Mole_Female.png'),
          zIndex: zBlemish,
          alt: "Mole",
        },
        fPurpleLipstick: {
          image: require('./Assets/Traits/unshaded/other/female/PurpleLipstick.png'),
          zIndex: zMouth,
          alt: "Purple Lipstick",
        },
        fRosyCheeks: {
          image: require('./Assets/Traits/unshaded/other/female/RosyCheeks_Female.png'),
          zIndex: zBlemish,
          alt: "Rosy Cheeks",
        },
        fSilverChain: {
          image: require('./Assets/Traits/unshaded/other/female/SilverChain_Female.png'),
          zIndex: zNeck,
          alt: "Silver Chain",
        },
        fSpots: {
          image: require('./Assets/Traits/unshaded/other/female/Spots_Female.png'),
          zIndex: zBlemish,
          alt: "Spots",
        },
        mBuckTeeth: {
          image: require('./Assets/Traits/unshaded/other/male/BuckTeeth.png'),
          zIndex: zMouth,
          alt: "Buck Teeth",
        },
        mClownNose: {
          image: require('./Assets/Traits/unshaded/other/male/ClownNose_Male.png'),
          zIndex: zNose,
          alt: "Clown Nose",
        },
        mEarring: {
          image: require('./Assets/Traits/unshaded/other/male/Earring_Male.png'),
          zIndex: zEar,
          alt: "Earring",
        },
        mFrown: {
          image: require('./Assets/Traits/unshaded/other/male/Frown.png'),
          zIndex: zMouth,
          alt: "Frown",
        },
        mGoldChain: {
          image: require('./Assets/Traits/unshaded/other/male/GoldChain_Male.png'),
          zIndex: zNeck,
          alt: "Gold Chain",
        },
        mMedicalMask: {
          image: require('./Assets/Traits/unshaded/other/male/MedicalMask_Male.png'),
          zIndex: zMouthProp,
          alt: "Medical Mask",
        },
        mMole: {
          image: require('./Assets/Traits/unshaded/other/male/Mole_Male.png'),
          zIndex: zBlemish,
          alt: "Mole",
        },
        mRosyCheeks: {
          image: require('./Assets/Traits/unshaded/other/male/RosyCheeks_Male.png'),
          zIndex: zBlemish,
          alt: "Rosy Cheeks",
        },
        mSilverChain: {
          image: require('./Assets/Traits/unshaded/other/male/SilverChain_Male.png'),
          zIndex: zNeck,
          alt: "Silver Chain",
        },
        mSmile: {
          image: require('./Assets/Traits/unshaded/other/male/Smile.png'),
          zIndex: zMouth,
          alt: "Smile",
        },
        mSpots: {
          image: require('./Assets/Traits/unshaded/other/male/Spots_Male.png'),
          zIndex: zBlemish,
          alt: "Spots",
        },
        othersNone: {
          image: require('./Assets/Traits/background/none.png'),
          zIndex: zBlemish, //but can be any really
          alt: "None",
        },
        // Other male mouth traits...
        fBTCglasses: {
          image: require('./Assets/Traits/unshaded/special/female/BTCglasses.png'),
          zIndex: zEyes,
          alt: "BTC Shades",
        },
        fNouns: {
          image: require('./Assets/Traits/unshaded/special/female/Nouns.png'),
          zIndex: zEyes,
          alt: "Nouns Glasses",
        },
        fPurpleHearts: {
          image: require('./Assets/Traits/unshaded/special/female/PurpleHearts.png'),
          zIndex: zEyes,
          alt: "Purple Hearts Shades",
        },
        fAppleVR: {
          image: require('./Assets/Traits/unshaded/special/female/appleVR.png'),
          zIndex: zEyes,
          alt: "Apple Vision Pro",
        },
        mBTCglasses: {
          image: require('./Assets/Traits/unshaded/special/male/BTCglasses.png'),
          zIndex: zEyes,
          alt: "BTC Shades",
        },
        mNouns: {
          image: require('./Assets/Traits/unshaded/special/male/Nouns.png'),
          zIndex: zEyes,
          alt: "Nouns Glasses",
        },
        mPurpleHearts: {
          image: require('./Assets/Traits/unshaded/special/male/PurpleHearts.png'),
          zIndex: zEyes,
          alt: "Purple Hearts Shades",
        },
        mAppleVR: {
          image: require('./Assets/Traits/unshaded/special/male/appleVR.png'),
          zIndex: zEyes,
          alt: "Apple Vision Pro",
        },
        Bubblegum: {
          image: require('./Assets/Traits/unshaded/other/Bubblegum.png'),
          zIndex: zMouthProp,
          alt: "Bubblegum",
        },
        Whistle: {
          image: require('./Assets/Traits/unshaded/other/Whistle.png'),
          zIndex: zMouthProp,
          alt: "Whistle",
        },
        fMcDeesCap: {
          image: require('./Assets/Traits/unshaded/special/female/McDeesCap.png'),
          zIndex: zHair,
          alt: "Down Bad M Cap",
        },
        mMcDeesCap: {
          image: require('./Assets/Traits/unshaded/special/male/McDeesCap.png'),
          zIndex: zHair,
          alt: "Down Bad M Cap",
        },
        fInNOutHat: {
          image: require('./Assets/Traits/unshaded/special/female/In-N-OutHat.png'),
          zIndex: zHair,
          alt: "Down Bad InOut Hat",
        },
        mInNOutHat: {
          image: require('./Assets/Traits/unshaded/special/male/In-N-OutHat.png'),
          zIndex: zHair,
          alt: "Down Bad InOut Hat",
        },

        //Expansion Punk Traits

        fxAlien: {
          image: require('./Assets/Traits/unshaded/base/xAlien_Female.png'),
          zIndex: zBase,
          alt: "Female Alien",
        },
        fxApe: {
          image: require('./Assets/Traits/unshaded/base/xApe_Female.png'),
          zIndex: zBase,
          alt: "Female Ape",
        },
        fxZombie: {
          image: require('./Assets/Traits/unshaded/base/xZombie_Female.png'),
          zIndex: zBase,
          alt: "Female Zombie",
        },
        fxBeanie: {
          image: require('./Assets/Traits/unshaded/hair/female/xBeanie_Female.png'),
          zIndex: zHair,
          alt: "Beanie",
        },
        fxCapForward: {
          image: require('./Assets/Traits/unshaded/hair/female/xCapForward_Female.png'),
          zIndex: zHair,
          alt: "Cap Forward",
        },
        fxCowboyHat: {
          image: require('./Assets/Traits/unshaded/hair/female/xCowboyHat_Female.png'),
          zIndex: zHair,
          alt: "Cowboy Hat",
        },
        fxFedora: {
          image: require('./Assets/Traits/unshaded/hair/female/xFedora_Female.png'),
          zIndex: zHair,
          alt: "Fedora",
        },
        fxHoodie: {
          image: require('./Assets/Traits/unshaded/hair/female/xHoodie_Female.png'),
          zIndex: zHair,
          alt: "Hoodie",
        },
        fxPoliceCap: {
          image: require('./Assets/Traits/unshaded/hair/female/xPoliceCap_Female.png'),
          zIndex: zHair,
          alt: "Police Cap",
        },
        fxTopHat: {
          image: require('./Assets/Traits/unshaded/hair/female/xTopHat_Female.png'),
          zIndex: zHair,
          alt: "Top Hat",
        },

      //new eye traits
        fARglass: {
          image: require('./Assets/Traits/unshaded/eyes-new/female/ARglass.png'),
          zIndex: zEyes,
          alt: "AR Glass",
        },
        fHalfFrameBlack: {
          image: require('./Assets/Traits/unshaded/eyes-new/female/Half_Frame_Black.png'),
          zIndex: zEyes,
          alt: "Half Frame Black",
        },
        fHalfFrameGreen: {
          image: require('./Assets/Traits/unshaded/eyes-new/female/Half_Frame_Green.png'),
          zIndex: zEyes,
          alt: "Half Frame Green",
        },
        fHalfFrameThin: {
          image: require('./Assets/Traits/unshaded/eyes-new/female/Half_Frame_Thin.png'),
          zIndex: zEyes,
          alt: "Half Frame Thin",
        },
        fOval: {
          image: require('./Assets/Traits/unshaded/eyes-new/female/Oval.png'),
          zIndex: zEyes,
          alt: "Oval Glasses",
        },
        fCybervisor: {
          image: require('./Assets/Traits/unshaded/eyes-new/female/Cybervisor.png'),
          zIndex: zEyes,
          alt: "Cybervisor",
        },
        fRoundGlasses: {
          image: require('./Assets/Traits/unshaded/eyes-new/female/RoundGlasses.png'),
          zIndex: zEyes,
          alt: "Round Glasses",
        },
        fElton: {
          image: require('./Assets/Traits/unshaded/eyes-new/female/Elton.png'),
          zIndex: zEyes,
          alt: "Elton Shades",
        },
        mARglass: {
          image: require('./Assets/Traits/unshaded/eyes-new/male/ARglass.png'),
          zIndex: zEyes,
          alt: "AR Glass",
        },
        mHalfFrameBlack: {
          image: require('./Assets/Traits/unshaded/eyes-new/male/Half_Frame_Black.png'),
          zIndex: zEyes,
          alt: "Half Frame Black",
        },
        mHalfFrameGreen: {
          image: require('./Assets/Traits/unshaded/eyes-new/male/Half_Frame_Green.png'),
          zIndex: zEyes,
          alt: "Half Frame Green",
        },
        mHalfFrameThin: {
          image: require('./Assets/Traits/unshaded/eyes-new/male/Half_Frame_Thin.png'),
          zIndex: zEyes,
          alt: "Half Frame Thin",
        },
        mOval: {
          image: require('./Assets/Traits/unshaded/eyes-new/male/Oval.png'),
          zIndex: zEyes,
          alt: "Oval Glasses",
        },
        mCybervisor: {
          image: require('./Assets/Traits/unshaded/eyes-new/male/Cybervisor.png'),
          zIndex: zEyes,
          alt: "Cybervisor",
        },
        mRoundGlasses: {
          image: require('./Assets/Traits/unshaded/eyes-new/male/RoundGlasses.png'),
          zIndex: zEyes,
          alt: "Round Glasses",
        },
        mElton: {
          image: require('./Assets/Traits/unshaded/eyes-new/male/Elton.png'),
          zIndex: zEyes,
          alt: "Elton Shades",
        },

      //New female hair traits
        fBlack_CP_Cap: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Black_CP_Cap.png'),
          zIndex: zHair,
          alt: "Black CP Cap",
        },
        fBob_black: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Bob_black.png'),
          zIndex: zHair,
          alt: "Black Bob",
        },
        fBob_blonde: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Bob_blonde.png'),
          zIndex: zHair,
          alt: "Blonde Bob",
        },
        fBob_brown: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Bob_brown.png'),
          zIndex: zHair,
          alt: "Brown Bob",
        },
        fBob_white: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Bob_white.png'),
          zIndex: zHair,
          alt: "White Bob",
        },
        fBun_black: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Bun_black.png'),
          zIndex: zHair,
          alt: "Black Bun",
        },
        fBun_blonde: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Bun_blonde.png'),
          zIndex: zHair,
          alt: "Blonde Bun",
        },
        fBun_brown: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Bun_brown.png'),
          zIndex: zHair,
          alt: "Brown Bun",
        },
        fBun_white: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Bun_white.png'),
          zIndex: zHair,
          alt: "White Bun",
        },
        fFringe_black: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Fringe_black.png'),
          zIndex: zHair,
          alt: "Black Fringe",
        },
        fFringe_blonde: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Fringe_blonde.png'),
          zIndex: zHair,
          alt: "Blonde Fringe",
        },
        fFringe_brown: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Fringe_brown.png'),
          zIndex: zHair,
          alt: "Brown Fringe",
        },
        fFringe_white: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Fringe_white.png'),
          zIndex: zHair,
          alt: "White Fringe",
        },
        fGenuine_Mohawk_Pink: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Genuine_Mohawk_Pink.png'),
          zIndex: zHair,
          alt: "Genuine Spiky Pink",
        },
        fGenuine_Mohawk_Red: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Genuine_Mohawk_Red.png'),
          zIndex: zHair,
          alt: "Genuine Spiky Red",
        },
        fPonytail_black: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Ponytail_black.png'),
          zIndex: zHair,
          alt: "Black Ponytail",
        },
        fPonytail_blonde: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Ponytail_blonde.png'),
          zIndex: zHair,
          alt: "Blonde Ponytail",
        },
        fPonytail_brown: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Ponytail_brown.png'),
          zIndex: zHair,
          alt: "Brown Ponytail",
        },
        fPonytail_white: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Ponytail_white.png'),
          zIndex: zHair,
          alt: "White Ponytail",
        },
        fRed_Cap_Reversed: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Red_Cap_Reversed.png'),
          zIndex: zHair,
          alt: "Red Cap Reversed",
        },
        fShort_black: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Short_black.png'),
          zIndex: zHair,
          alt: "Short Black",
        },
        fShort_blonde: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Short_blonde.png'),
          zIndex: zHair,
          alt: "Short Blonde",
        },
        fShort_brown: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Short_brown.png'),
          zIndex: zHair,
          alt: "Short Brown",
        },
        fShort_white: {
          image: require('./Assets/Traits/unshaded/hair-new/female/Short_white.png'),
          zIndex: zHair,
          alt: "Short White",
        },
        fWSB: {
          image: require('./Assets/Traits/unshaded/hair-new/female/WSB.png'),
          zIndex: zHair,
          alt: "WSB",
        },

      //New male hair traits
        mAfro: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Afro.png'),
          zIndex: zHair,
          alt: "Afro",
        },
        mBecks: {
          image: require('./Assets/Traits/unshaded/hair-new/male/becks.png'),
          zIndex: zHair,
          alt: "Brush it like Becks",
        },
        mBlackCPCap: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Black_CP_Cap.png'),
          zIndex: zHair,
          alt: "Black CP Cap",
        },
        mCenterParting: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Center_parting.png'),
          zIndex: zHair,
          alt: "Centre Parting",
        },
        mChairman: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Chairman.png'),
          zIndex: zHair,
          alt: "Chairman",
        },
        mEdwardian: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Edwardian.png'),
          zIndex: zHair,
          alt: "Edwardian",
        },
        mElvis: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Elvis.png'),
          zIndex: zHair,
          alt: "Elvis",
        },
        mGenuineMohawkPink: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Genuine_Mohawk_Pink.png'),
          zIndex: zHair,
          alt: "Genuine Spiky Pink",
        },
        mGenuineMohawkRed: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Genuine_Mohawk_Red.png'),
          zIndex: zHair,
          alt: "Genuine Spiky Red",
        },
        mGeorgian: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Georgian.png'),
          zIndex: zHair,
          alt: "Georgian",
        },
        mKPop: {
          image: require('./Assets/Traits/unshaded/hair-new/male/kpop.png'),
          zIndex: zHair,
          alt: "K-Pop Moptop",
        },
        mLongBlonde: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Long_Blonde.png'),
          zIndex: zHair,
          alt: "Long Blonde",
        },
        mMediumBlack: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Medium_black.png'),
          zIndex: zHair,
          alt: "Medium Black",
        },
        mMediumBlonde: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Medium_blonde.png'),
          zIndex: zHair,
          alt: "Medium Blonde",
        },
        mMediumBrown: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Medium_brown.png'),
          zIndex: zHair,
          alt: "Medium Brown",
        },
        mRedCapReversed: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Red_Cap_Reversed.png'),
          zIndex: zHair,
          alt: "Red Cap Reversed",
        },
        mShaggyBlack: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Shaggy_black.png'),
          zIndex: zHair,
          alt: "Shaggy Black",
        },
        mShaggyBlonde: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Shaggy_blonde.png'),
          zIndex: zHair,
          alt: "Shaggy Blonde",
        },
        mShaggyBrown: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Shaggy_brown.png'),
          zIndex: zHair,
          alt: "Shaggy Brown",
        },
        mShortBlack: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Short_black.png'),
          zIndex: zHair,
          alt: "Short Black",
        },
        mShortBlonde: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Short_blonde.png'),
          zIndex: zHair,
          alt: "Short Blonde",
        },
        mShortBrown: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Short_brown.png'),
          zIndex: zHair,
          alt: "Short Brown",
        },
        mSideflop: {
          image: require('./Assets/Traits/unshaded/hair-new/male/Sideflop.png'),
          zIndex: zHair,
          alt: "Side Flop",
        },
        mWSB: {
          image: require('./Assets/Traits/unshaded/hair-new/male/WSB.png'),
          zIndex: zHair,
          alt: "WSB",
        },


      }
 