import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
document.title = 'The Punk Builder: build a punk from over 30 classic traits, by The Punk Animator';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
