import React, { useState, useEffect, useRef } from 'react';
import GlistenGif from './Assets/Top Hat logo glisten2.gif';
import TopHatMenuGif from './Assets/Top Hat logo menu2.gif';
import TipHatGif from './Assets/TPA tip hat.gif';

const TopHatMenu = () => {
  const [isMenuGif, setIsMenuGif] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayContent, setOverlayContent] = useState('');
  const timeoutRef = useRef(null);

  const changeToMenuGif = () => {
    setIsMenuGif(true);
    setOverlayContent(''); // Clear the overlay content
    setShowOverlay(false); // Hide the overlay
    resetTimeout();
  };

  const handleAreaClick = (content) => {
    setShowOverlay(true);
    setOverlayContent(content);
  };

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setIsMenuGif(false);
    }, 6900);
  };

  useEffect(() => {
    resetTimeout();

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div>
      <img
        id="TopHatMenu"
        src={isMenuGif ? TopHatMenuGif : GlistenGif}
        alt="Top Hat dropdown menu"
        onMouseEnter={resetTimeout}
        className="top-hat-menu"
        style={{ imageRendering: 'pixelated' }}
        useMap="#menuMap"
      />

    {!isMenuGif && (
      <map name="menuMap">
        <area
          shape="rect"
          coords="1, 0, 16, 9" //only the top hat will turn cursor to a pointer
          onClick={changeToMenuGif}
          alt="clickhat"
          className="clickable-area"
        />
      </map>
    )}

    {isMenuGif && (
      <map name="menuMap">
        <area
          shape="rect"
          coords="19, 1, 47, 9"
          onClick={() => handleAreaClick('Why')}
          alt="Why"
          className="clickable-area"
        />
        <area
          shape="rect"
          coords="53, 1, 83, 9"
          onClick={() => handleAreaClick('Updates')}
          alt="Updates"
          className="clickable-area"
        />
        <area
          shape="rect"
          coords="89, 1, 118, 9"
          onClick={() => handleAreaClick('Support')}
          alt="Support"
          className="clickable-area"
        />
      </map>
    )}

      {showOverlay && (
        <div className="overlayMenu" onClick={() => setShowOverlay(false)}>
          <div className="overlayMenu-content">
            {overlayContent === 'Why' && (
              <div>
                <h1>Why... did I make this website?</h1>
                <h5>Simply because I love punks, and want holders - and non-holders - to enjoy the CryptoPunks project in a fun and interactive way.</h5>
                <h5>Now you can build one of over 10 million possible punks - one that you vibe with perhaps, or looks more like you? Or maybe this is like a wardrobe of your own punk.. a bad hair day? Take a trip into the virtual world with a new headset.. A special occasion coming up? Get dapper with a new hat.. How about the new Walking Dead movie.. switch to a zombie version effortlessly!</h5>
                <h5>The options are limitless!</h5>
                <h5>In the future, I plan to add more fun traits for you to try and share, with a goal to be able to animate each punk too.</h5>
                <h5>Punks truly are a wonderful, significant, and historic collection. Find out more about the official CryptoPunks project <a href="https://cryptopunks.app/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'fuchsia' }}>here</a>.</h5>
                <div className="blank-line"></div>
                <h6>This website is not affiliated with the CryptoPunks project created originally by <a href="https://www.larvalabs.com/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'fuchsia' }}>Larva Labs</a> and currently owned by <a href="https://yuga.com/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'fuchsia' }}>Yuga Labs</a>.</h6>
              </div>
            )}
            {overlayContent === 'Updates' && (
              <div>
                <h1>Current developments (as of June 30, 2023):</h1>
                <h6>+ Add new city traits, mouth traits.</h6>
                <h6>+ Integrate punk animation tool</h6>
                <div className="blank-line"></div>
                <h1>Previous updates:</h1>
                <h6><span class="version-highlight">Version 1.6</span> - added 'OG traits' button to select between original CryptoPunk traits and all traits. (June 30, 2023)</h6>
                <h6><span class="version-highlight">Version 1.5</span> - top menu bar added. (June 24, 2023)</h6>
                <h6><span class="version-highlight">Version 1.4</span> - multiple new hair and eye traits added. (June 23, 2023)</h6>
                <h6><span class="version-highlight">Version 1.3</span> - larger download size option, and filename to include matched punk ID. (June 21, 2023)</h6>
                <h6><span class="version-highlight">Version 1.2</span> - added a 'rules on/off' button to reflect original punk trait logic. (June 15, 2023)</h6>
                <h6><span class="version-highlight">Version 1.1</span> - highlight when selected traits matched an existing punk, together with short descriptions. (June 13, 2023)</h6>
                <h6><span class="version-highlight">Version 1.0</span> - The Punk Builder site launched on <a href="https://twitter.com/ThePunkAnimator/status/1666999638493126656" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'fuchsia' }}>6.9, or June 9th, 2023</a>, to commemorate the 6th anniversary of the iconic CryptoPunks project by <a href="https://www.larvalabs.com/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'fuchsia' }}>Larva Labs</a>.</h6>
              </div>
            )}
            {overlayContent === 'Support' && (
              <div>
                <h1>Want to support me?</h1>
                <h5>Thanks so much for even finding this page! You must really love what has been created here.. So let me share a bit more about myself..</h5>
                <h5>I got into NFTs in early 2021 and my goal from the very beginning was to get a punk, which I finally achieved after many months of flipping with fellow degens - shout out to the NFT Masters group! Punks to me, means I'm a believer, I'm a supporter, I'm in it.. this crazy world.. for the long term. And I love that punks represent the OG of the NFT PFP space.</h5>
                <h5>Then the bear happened..</h5>
                <h5>Fomo-no-more.. Perma-frown.. When... will markets come back?</h5>
                <h5>This is when I started to think about my punk.. <a href="https://cryptopunks.app/cryptopunks/details/6987" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'crimson' }}>6987</a>... and how I can help spread the punk meme. My eureka moment came when I realised just how often I used emojis in discord and beyond... So.. Why don't I make emojis of punk 6987?</h5>
                <h5>And that's how it started.. A gm emoji.. then a LOL, then thumbsup.. and before I knew it.. over 30 animated gifs were created. I made some for <a href="https://opensea.io/collection/punk-animations-by-tpa" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'crimson' }}>frens</a>, then for <a href="https://twitter.com/ThePunkAnimator/status/1621703725944283138" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'crimson' }}>over 100</a> fellow punk fam - all gratis. Eventually, it dawned on me.. my <span style={{ textDecoration: 'line-through', textDecorationColor: 'fuchsia', textDecorationThickness: '3px' }}>dream</span> obsession to animate all punks had started.</h5>
                <h5>Although my goal is still to animate all punks, I hit many setbacks including my fren/dev suddenly leaving the web3 space. So ChatGPT stepped in.. and this website was born.</h5>
                <h5>It has taken over 6 months of work, re-creating the traits <a href="https://twitter.com/ThePunkAnimator/status/1614815151412199424" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'crimson' }}>pixel by pixel</a>, then shading them, learning multiple coding languages, back and forth fixing bugs.. to finally get to this stage.</h5>
                <h5>However, this is just the start. I ultimately want to help make the punk brand more widely known, through animations, and hope that one day many more people can enjoy a pixelated profile picture! So if you want to support me and join me in this mission, I'm open to donations to help fund this endeavour. I have plans to recognise my supporters in a novel way so please feel free to donate at:</h5>
                <h3><a href="https://etherscan.io/address/0x956f3173afe0a7717f131d55fe924f720e1a953e" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'fuchsia' }}>support.thepunkanimator.eth</a></h3>
                <h5>.. really a sincere and appreciative thank you for getting to the end! Have a wonderful day!</h5>
                <div className="tip-hat-gif-container">
                <img
                    src={TipHatGif}
                    alt="Tip Hat"
                    className="tip-hat-gif"
                    style={{ imageRendering: 'pixelated' }}
                  />
                  </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TopHatMenu;
